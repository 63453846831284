import React from 'react'
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    default: {
      background: "none",
      color: "#6E6893",
      textTransform: "capitalize",
      minWidth: "max-content",
      minHeight: "max-content",

    },
    hover: {
        padding: '0px 5px',
        margin:'0px 5px',
      "&:hover": {
         height: '20%',
        background: "#d9d5e8",
        boxShadow: '0px 8px 15px rgb(11 38 173 / 20%)',
      },
    },
  })
);

export default function NotificationContainer({notifications}) {

    const classes = useStyles();
    return (
        <div className={classes.default}> 
            {notifications.length && notifications.map(notification =>{
               return  <li className={classes.hover} style={{marginTop:'10px'}}>{notification}</li>
            })}
        </div>
    )
}
