import React from 'react'
import { Button as MuiButton, Typography, } from "@material-ui/core";
import { makeStyles, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
           
            [theme.breakpoints.down("lg")]: {
                minWidth: 32,
                
                
                fontSize:12,
                borderRadius:theme.spacing(1)
            },
            height:theme.spacing(10)
        },
        label: {
            textTransform: 'none'
        },
        primary: {
            background: '#EBC522',
            color: '#FFF',
            '&:hover': {
                background: '#f9bc06'
            }
        },
        disabled: {
            background: '#f50057',
            color: '#FFF',
            opacity: 0.5,
            '&:hover': {
                cursor: 'not-allowed',
            boxShadow: '0 0 0 0 rgba(0, 127, 255, 0)',
            }
            
        },
        secondary: {
            background: 'transparent',
            color: '#EBC522',
            border: 'solid 1px #EBC522',
            '&:hover': {
                background: '#f9bc06',
                color: '#FFF',
            }
        },
        opcional: {
            height: '50px',
            background: '#EBC522',
            color: '#FFF',
            '&:hover':{
                background: '#f9bc06'
            }
        },
        form: {
            background: '#EBC522',
            color: '#FFF',
            '&:hover': {
                background: '#f9bc06'
            },
            width: '100%',
            height: '100%',
            marginLeft:'1px',
            
        }
    }))
export default function Button(props) {

    const { text, size, onClick, variant, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
            className={classes[variant]}
            size={size || "large"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
            <Typography variant="body1">{text}</Typography>
        </MuiButton>
    )
}