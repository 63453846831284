import { useState } from 'react'

//hook para usar la paginacion de una tabla
export const usePagination = () => {
    const [page, setPage] = useState(0);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return { page, handleChangePage }
}