import { makeStyles, createStyles, Typography, Divider, Grid } from "@material-ui/core";
import Controls from "./Controls";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import ModalDialog from '../Modals/ModalDialog';
import useModal from '../../hooks/useModal';

import "moment/locale/es";

const FormContainer = (
    { sizeTitle = "h5",
        height = '100%',
        width = '100%',
        textoConfirmacion = '¿Desea confirmar esta accion?',
        children,
        title,
        buttonResetForm= true,
        LabelButtonSubmit = "nuevo",
        e = ()=>{},
        resetForm = () => { },
        hidenButton = false,
        alignContent = "",
       
        ...other }
) => {

    const modalConfirmacion = useModal();

    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                width: width,
                background: "#FFF",
                height: '100%',

                '& .MuiFormControl-root': {
                    width: '100%',
                    height: '100%'
                },
            },
            fieldsContainer: {
                height: "80%",
                overflowX: 'hidden',
                padding: '10px !important'
            },
            formTitle: {
                fontWeight: 300,
            },
            formTitleContainer: {
                height: 'auto',
                margin: 10
            },
            buttons: {
                height: 'auto',
                alignItems: 'center'
            }

        }))

    

    const classes = useStyles();
    return (
        <form
            className={classes.root}
            autoComplete="off" 
            {...other}>
                {/* {
                    
            <ModalDialog
            title={title}
            accionAceptar={{
                ()=>onSubmit()
                }
                }
            closeModal={() => modalConfirmacion.closeModal()}
            active={modalConfirmacion.modal}
            
          //fondo='none'

          >
            
            <div>{textoConfirmacion}</div>
          </ModalDialog>
                 
        } */}
            <MuiPickersUtilsProvider locale='es' utils={MomentUtils}>
                <Grid className={classes.root} container spacing={2} >
                    <Grid item className={classes.formTitleContainer} xs={12}>
                        <Typography className={classes.formTitle} variant={sizeTitle}>{title}</Typography>
                    </Grid>

                    <Grid item className={classes.fieldsContainer} xs={12}>
                        <Grid container spacing={2}>
                            {children}
                        </Grid>
                    </Grid>
                    {
                        hidenButton
                            ? null
                            : <>
                                <Grid xs={12} item>
                                    <Divider />
                                </Grid>
                                <Grid spacing={1} container className={classes.buttons} xs={12} item>
                                    <Grid item xs={3}>
                                        
                                    </Grid>
                                    <Grid item container justify="flex-end" direction='row' xs={9}>
                                       {buttonResetForm && <Controls.Button
                                            text="Limpiar Formulario"
                                            variant="secondary"
                                            onClick={resetForm} />}
                                        <Controls.Button
                                            type="submit"
                                            //onClick={()=>modalConfirmacion.openModal()}
                                            variant="primary"
                                            text={LabelButtonSubmit} />
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Grid>
            </MuiPickersUtilsProvider>
        </form>
    )
}

export default FormContainer