
import { green } from '@material-ui/core/colors';
import PrintIcon from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
//import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckIcon from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LinkIcon from '@mui/icons-material/Link';
import FilePresentSharpIcon from '@mui/icons-material/FilePresentSharp';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//import DescriptionIcon from '@mui/icons-material/Description';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
//import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PasswordIcon from '@mui/icons-material/Password';
//import FilePresentIcon from '@mui/icons-material/FilePresent';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
//import ForwardIcon from '@mui/icons-material/Forward';
//import NoteIcon from '@mui/icons-material/Note';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import GroupIcon from '@mui/icons-material/Group';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { red } from '@mui/material/colors';

const Icons = (props) => {
    const { type = "" } = props

    const objIcon = {
        null: <></>,
        recibo: <ReceiptIcon fontSize="small" />,
        quitar: <HighlightOffIcon fontSize="small" />,
        imprimir: <PrintIcon fontSize="small" />,
        borrar: <DeleteIcon fontSize="small" />,
        nuevo: <PersonAddIcon fontSize="small" />,
        filter: <FilterListIcon fontSize="small" />,
        close: <CloseIcon fontSize="small" />,
        user: <AccountCircleIcon fontSize="small" />,
        logout: <PowerSettingsNewIcon fontSize="small" />,
        editar: <EditIcon fontSize="small" />,
        editarCustom:<EditIcon fontSize="small" />,
        agregar: <AddIcon fontSize="small" />,
        ctacte: <AccountBalanceWalletIcon fontSize="small" />,
        cerrar: <HighlightOffIcon fontSize="small" />,
        file: <FilePresentSharpIcon fontSize="small" />,
        view: <VisibilityIcon fontSize="small" />,
        bank: <AccountBalanceIcon fontSize="small"/>,
        true: <CheckIcon style={{ color: green[500] }} />,
        check: <CheckIcon fontSize="small"/>,
        false: <NotInterestedIcon style={{color:'#f48fb1'}} />,
        dobleFlecha: <DoubleArrowIcon fontSize="small"/>,
        payment:<PaymentIcon fontSize="small"/>,
        anotaciones:<AssignmentIcon fontSize="small"/>,
        finish: <HistoryEduIcon  />,
        link: <LinkIcon fontSize="small"/>,
        download: <FileDownloadIcon fontSize="small"/>,
        flechaDerecha: <ArrowRightAltIcon fontSize="small"/>,
        password: <PasswordIcon fontSize="small"/>,
        upload: <UploadFileIcon style={{color: '#6E6893'}} fontSize='small' />,
        description: <TextSnippetIcon fontSize="small"/>,
        trayectory: <LocationOnIcon fontSize="small"/>,
        activity: <CompareArrowsIcon fontSize="small"/>,
        note: <StickyNote2Icon fontSize="small"/>,
        noteadd: <NoteAddIcon fontSize="small"/>,
        send: <ArrowForwardIcon fontSize="small"/>,
        users: <GroupIcon fontSize="small"/>,
        document: <PostAddIcon style={{color: '#6E6893'}} fontSize='small' />,
        downloadoff: <FileDownloadOffIcon fontSize="small"/>,
        quitar: <CloseIcon fontSize="small" style={{ color: red[500] }}/>,
    }

    return <> {objIcon[type]} </>
}


export default Icons