import React,{useState} from 'react'
import { IconButton, InputBase, Paper, Divider, Select, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
       
      },
    },
  };

const useStyles = makeStyles((theme) =>

    createStyles({
        root: {
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            background: "#FFF",
            borderRadius: 5,
            maxWidth: "100vh",
            minWidth: 300,
            height: 40,
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
        },
        input: {
            color: "#6E6893",
            marginLeft: theme.spacing(1),
            flex: 1,
            '& ::placeholder': {
                fontSize: 12
            },
            fontSize: 14,
        },
        select: {
            color: "#6E6893",
            padding: 5,
            fontSize: 12,
            "& :focus": {
                background: '#FFF'
            },
            maxHeight:'10px',
        },
        iconButton: {
            color: "#6E6893",
            padding: 5,
            border: "none"
        },
        divider: {
            height: 26,
            margin: 2,
            marginLeft:'20px',
        },
    }),
);



export default function SearchBarMultiple({setSearchText, width = '100%', closeModal = () => { }, changeBuscar = () => { }  }) {

    const classes = useStyles();

    const [numeroText, setNumeroText] = useState('')
    const [anio, setAnio] = useState('No seleccionado')
    const [caratulaText, setCaratulaText] = useState('')


    const onChangeSelect = (e) =>{
        console.log('anio: ',e.target.value)
        setAnio(e.target.value)
    }


    const onClick = () => {

        /* setSearchAnio(values.anio);
        setSearchNumero(values.numero);
        setSearchCaratula(values.caratula); */

        let valuesArray = []

        let valueAnio = anio;

        valuesArray.push(numeroText);
        (anio === 'No seleccionado')? valuesArray.push(''): valuesArray.push(anio);
        valuesArray.push(caratulaText);
        //console.log('search',search)

        setSearchText(valuesArray)

        changeBuscar()

        setAnio(valueAnio)



        closeModal();
    }

    const clearFilters = () =>{

        setNumeroText('');
        setAnio('No seleccionado');
        setCaratulaText('');

        setSearchText(['','',''])

        changeBuscar();

        closeModal();

    }

    const anioInicial = 1994

    let fecha = new Date();

    let anioActual = fecha.getFullYear();


    let anioArray = [];

    anioArray.push('No seleccionado');

    for(let i=0; i<((anioActual-anioInicial)+1); i++){

        anioArray.push((anioActual-i));

    }

    return (

        <Paper className={classes.root}>
           

           
            <Tooltip title='Limpiar filtros' >
            <IconButton className={classes.iconButton} aria-label="search" onClick={clearFilters}>
                <CloseIcon />
            </IconButton>
            </Tooltip>
            <Divider className={classes.divider} orientation="vertical" />
            <InputLabel id="demo-multiple-name-label" sx={{fontWeight:'lighter', fontSize:'0.9rem',ml:1}}> Año: </InputLabel>
            <Select
                onChange={onChangeSelect}
                className={classes.select}
                disableUnderline
                value={anio}
                MenuProps={MenuProps}
            >
                {
                    anioArray.length && anioArray.map((data, index) => {
                        return (
                            <MenuItem key={index} value={data}>{data}</MenuItem>
                        )
                    })
                }
            </Select>
            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
                onKeyDown={(e) => {
                    if (e.keyCode === 13) onClick();
                }}
                className={classes.input}
                placeholder={`Buscar por Numero`}
                onChange={(e) => {
                    setNumeroText(e.target.value)
                }}
                value={numeroText}
            />
            
          
            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
                onKeyDown={(e) => {
                    if (e.keyCode === 13) onClick();
                }}
                className={classes.input}
                placeholder={`Buscar por Caratula`}
                onChange={(e) => {
                    setCaratulaText(e.target.value)
                }}
                value={caratulaText}
            />

            <Divider className={classes.divider} orientation="vertical" />
            
            
            <Tooltip title='Buscar'>
            <IconButton className={classes.iconButton} aria-label="search" onClick={onClick}>
                <SearchIcon />
            </IconButton>
            </Tooltip>
           
        </Paper>


    )
}
