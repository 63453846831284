import { useState } from 'react';
import { Grid} from '@material-ui/core';
import FormContainer from '../../components/Forms/FormContainer';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';


import IconButton from '@mui/material/IconButton';



// Import components
import Controls from '../../components/Forms/Controls'







//Api
import { UserApi } from '../../api/UserApi';


export default function FormNewPassword({ data, recargarTabla, fnCloseModal = () => { } }) {

  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);




  //funciones para utilizar el formulario traidas desde el hook useForm
  const schema = yup
    .object({
      repeatPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
      userId: data.id,
    },
    resolver: yupResolver(schema),
  });





  const handleClickShowPassword = (setVisibility, value) => {
    setVisibility(!value);
  };



  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const onSubmit = async (data, e) => {
    e.preventDefault();

    delete data.repeatPassword;


    await new UserApi()
      .updatePassword(data)
      .then((response) => {
        enqueueSnackbar("Cargado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      });


  }

  console.log('data new password: ', data)


  return (
    <FormContainer

      LabelButtonSubmit="Guardar"
      title={`Cambiar contraseña de ${data.username}`}
      buttonResetForm={false}
      autocomplete='off'
      onSubmit={handleSubmit(onSubmit)}>


      {/* <Grid style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}item xs={12}>
    <Typography>{`Cambiar contraseña del usuario ${data.username}`}</Typography>
    </Grid>
    <Grid container item xs={12}></Grid> */}


      <Grid container item xs={12}>



        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value } }) =>
            <Controls.Input
              type={showPassword ? 'text' : 'password'}
              fullwidth
              InputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleClickShowPassword(setShowPassword, showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
              }
              error={errors.repeatPassword ? true : false}
              label='Contraseña'
              name='password'
              value={value}
              onChange={onChange}

            />}
        />



      </Grid>

      <Grid container item xs={12}></Grid>

      <Grid container item xs={12}>
        <Controller
          name="repeatPassword"
          control={control}
          render={({ field: { onChange, value } }) => <Controls.Input
            type={showRepeatPassword ? 'text' : 'password'}
            fullwidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={() => handleClickShowPassword(setShowRepeatPassword, showRepeatPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showRepeatPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            }
            }
            autoComplete="off"
            error={errors.repeatPassword ? true : false}
            label='Repetir Contraseña'
            name='repeatPassword'
            value={value}
            onChange={onChange}

          />}
        />
      </Grid>

      {errors.repeatPassword && <Grid item xs={12}>{<p style={{ color: '#ff1414', marginTop: '0px' }}>{errors.repeatPassword?.message}</p>}</Grid>}


    </FormContainer>
  );
}
