import * as React from 'react';
import {Grid} from '@material-ui/core';
import Autocomplete from "@mui/material/Autocomplete";
import { useSnackbar } from "notistack";

//components
import FormContainer from '../../components/Forms/FormContainer';
import Controls from '../../components/Forms/Controls'


//hooks
import { useForm} from "react-hook-form";
//utils
import {decodeToken} from "../../helpers/authToken";

//api
import { UserApi } from '../../api/UserApi';
import {TrayectoriasApi} from '../../api/TrayectoriasApi';



export default function EnviarConvenio({recargarTabla,idexpediente,caratula="",fnCloseModal=()=>{}}) {

  

  const { enqueueSnackbar } = useSnackbar();
 
   const [users,setUsers] = React.useState([]);


   React.useEffect( () => {

    async function fetchData(){

    
        await new UserApi().getAllUsers()
        .then(resp=>{
            setUsers(resp.data);
        })
    }

    fetchData();

   

  },[])


  const handleOnChange = (e,data,setData)=> {
    e.preventDefault();
    console.log('selectedUser: ',data);
    setData('user_id',data.id);
    setData('email',data.email_address);
    
  
  }



  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      idexpediente: idexpediente,
      user_id: '',
      email:'',
      caratula: caratula,
      userSender: decodeToken("name")?decodeToken("name"):"usuario",
    }
  });



 /*  const {
    values,
    handleChangeForm,
    resetForm,
  } = useForm(initialFValues);

  const {formSubmit} = useSubmit(new TrayectoriasApi().enviarExpediente, values, recargarTabla, ()=>{}, fnCloseModal) */

  //console.log("valores enviarConvenio: ",values)
  



  const onSubmit = async(data) =>{
    
    console.log('onSUbmit: ',data)
    await new TrayectoriasApi()
    .enviarExpediente(data)
    .then((response) => {
      enqueueSnackbar("Convenio enviado", { variant: "success" });
      recargarTabla();
      fnCloseModal();
    })
    .catch((err) => {
      enqueueSnackbar("No se pudo enviar el convenio", { variant: "error" });
    });
  }

  
  return (
    <FormContainer
        LabelButtonSubmit="Enviar" 
        buttonResetForm={false}
        title= {"Enviar Convenio"}
        onSubmit={handleSubmit(onSubmit)}
    >
       {/*  <Grid  item xs={12}>
        <Controls.AutocompleteSelect 
        promSelectList={new UserApi().getAllUsers()}
        filtro="nombre"
        name='user_id'
        valorSalida='id'
        onChange={handleChangeForm}
        ></Controls.AutocompleteSelect>
        </Grid> */}
        <Grid item xs={12}>
        <Autocomplete
            fullWidth={true}
          
            onChange={(event, values) => {
              
              handleOnChange(event,values,setValue);
            }}
            id="id"
            size={'medium'}
            options={users}
            groupBy={(option) => option.areas}
            getOptionLabel={(option) => option.nombre}
            
            renderInput={(params) => (
              <Controls.Input
                  required
                  {...params}
                  label='Usuarios'
                  size="small"
                  placeholder='Usuarios'
                  variant="outlined"
              />
          )}
          /> 
        </Grid>
    </FormContainer>
  );
}