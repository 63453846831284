import React, { useState, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ButtonIcon from '../Buttons/ButtonIcon';
import { makeStyles, createStyles } from '@material-ui/core/styles';

//contexto
import { AppStateContext } from '../../Contexts/AppStateContextProvider';


import {decodeToken} from '../../helpers/authToken';

//obtener el contexto de la aplicacion



const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 'max-content',
            display: 'inline-block'
        },
        accion: {
            padding: '0px 5px'
        }
    }))

const ActionMenu = ({ data = {}, options = [], paramAction = null,setConvenio=()=>{}, setExpedienteId = () => { } }) => {
    const appStateContext = useContext(AppStateContext);
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //console.log('data: ',data)

    const onClick = () => {

        //setExpedienteId(data.id)

    }

    

    return (
        <div className={classes.root} onClick={onClick}>
            <IconButton
                style={{ padding: 0 }}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            { open && <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                {options.map((option) => (
                    <li className={classes.accion} key={option.title} onClick={handleClose}>
                        
                        {
                            
                        (option === '' 
                        || ((data.conveniomarcoid === '0' || data.conveniomarcoid === 0 || data.conveniomarcoid === null) && option.title === 'Ver Convenio marco') 
                        || ((data.ubicacion !== decodeToken('name')) && option.title === 'Enviar')
                        || ((data.documentlink !== null && option.title === 'Enviar'))
                        || (data.documentlink === null && option.title === 'Link Google Docs')) ? null :
                            <ButtonIcon
                                onClick={() => {
                                    appStateContext.setAppState("");
                                    
                                    setConvenio(data);
                                    appStateContext.setAppState(data.id)
                                    localStorage.setItem('datosPdf',JSON.stringify(data))
                                    setExpedienteId(data.id)
                                    option.action(data)
                                }}
                                textAlign="left"
                                startIcon={option.icon}
                                children={option.element ? option.element : null}
                                width="100%"
                                tooltipTitle={option.tooltipTitle}
                                label={option.title}
                            />}
                    </li>
                ))}
            </Menu>}
        </div>
    );
}

export default ActionMenu