
import {useContext,useState,useEffect} from 'react'
import { Grid} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
//import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField'
import FormContainer from '../../Forms/FormContainer';
import Divider from '@mui/material/Divider';

// Import components
import Controls from '../../Forms/Controls'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from "notistack";
import Autocomplete from "@mui/material/Autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Custom hooks
import { useForm, Controller } from "react-hook-form";

//utils
import {generarNombreCaratula} from '../../../helpers/utils';
import {decodeToken} from '../../../helpers/authToken';


//Api
import { InformesApi } from '../../../api/InformesApi';
import { UserApi } from "../../../api/UserApi";

//contexto
import { AppStateContext } from '../../../Contexts/AppStateContextProvider';

//valores iniciales del formulario
const initialFValues = {
  caratula: '',
  descripcion: '',
  fecha: '',
  fechafin: '',
  idexpediente: '',
  created_by: localStorage.id,
  updated_by: localStorage.id
  
}

const useStyles = makeStyles((theme) =>

  createStyles({
      root: {
          padding: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          background: "#FFF",
          borderRadius: 5,
          maxWidth: "100vh",
          minWidth: 300,
          height: 40,
          boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
      },
      divider: {
          width: '100%',
          margin: '20px',
      },
  }),
);





//formulario para cargar una nueva actividad
const FormInformes = ({recargarTabla , fnCloseModal=()=>{}}) => {

  const classes = useStyles();
  //funciones para utilizar el formulario traidas desde el hook useForm
  const schema = yup
    .object({
      descripcion: yup.string().required("Este campo es requerido"),   
    })
    .required();

  const {
    control,
    handleSubmit
  } = useForm({
    defaultValues: {
      idexpediente: "",
      descripcion: "",
      descripcion_categoria: "",
      fecha_informe:"",
      tiene_descripcion_categoria: 0,
      usuario_id: decodeToken('id')
    }
  });

  const matchFullHD = useMediaQuery('(min-width:2000px)');
  const { enqueueSnackbar } = useSnackbar();


  //const [users, setUsers] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [componenteDescripcionCategoria, setDescripcionCategoria] = useState(false);
  
  //obtencion del id de expediente
  const appStateContext = useContext(AppStateContext);
  const idexpediente = appStateContext.appState;
 
 
  useEffect( () => {

    async function fetchData(){
  
      const result = await new InformesApi().getCategoriasInforme();
  
        console.log(result)
        setCategorias(result.data);
    }
  
    fetchData();
  
   
  
  },[])


  const handleOnChangeAutocomplete = (event, onChange, value) => {

    console.log('categorias valores: ',value)

    onChange({ target: { value: value !== null ? value : "" } });

    if (value?.idcategoriainformes === 7) {

      setDescripcionCategoria(true);

    } else {

      setDescripcionCategoria(false);

    }


  };
  

  //funcion submit del formulario 
  //const { formSubmit } = useSubmit( new InformesApi().addInformesDeExpediente, values, recargarTabla,resetForm, fnCloseModal)

  const onSubmit = async (data, e) => {


    let factual = new Date();

    const archivoForm = new FormData(e.target);

    console.log('archivo: ',archivoForm);

    console.log('values: ', archivoForm.getAll('archivo'))
    
    
    data.fecha_informe = new Date();
    //factual.getDay()+"/"+factual.getMonth()+"/"+factual.getFullYear();

    data.categoria_id = data.categorias.idcategoriainformes;

    delete data.categorias;

    //console.log(data);

    data.tiene_descripcion_categoria = componenteDescripcionCategoria;

    data.idexpediente= idexpediente;



   const informeId =  await new InformesApi()
      .addInformeDeExpediente(data)
      .then((response) => {
        return response.data.insertId;

      })
      .catch((err) => {
        console.log('error: ',err.response.data.message);
        enqueueSnackbar(err.response.data.message? err.response.data.message: "No se ha podido crear el informe", { variant: "error" });
      });

      console.log('informe: ',informeId)
      archivoForm.append('informe_id', informeId)

      new InformesApi().addArchivoAInforme(archivoForm)
      .then((response) => {
        enqueueSnackbar("Archivo cargado con exito", { variant: "success" });
        enqueueSnackbar("informe cargado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        console.log("error en la solicitud: ",err.response)
        enqueueSnackbar("Error al cargar el archivo", { variant: "error" });
      });

  };

  

        return (
          <>
      <FormContainer
        LabelButtonSubmit="Guardar"
        buttonResetForm={false}
        //resetForm={resetForm}
        title={"Nuevo Informe"}
        onSubmit={handleSubmit(onSubmit)}
      >



            <Grid container item xs={12}>
              <Controller
                name="categorias"
                control={control}
                render={({ field: { onChange } }) => (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    size={matchFullHD ? 'medium' : 'small'}
                    id="categorias"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={categorias}
                    onChange={(event, values) =>
                      handleOnChangeAutocomplete(event, onChange, values)
                    }
                    getOptionLabel={(option) => option.nombre}
                    renderInput={(params) => (
                      <TextField {...params} required label="Categorias" />
                    )}


                  />
                )}
              />
            </Grid>
            <Grid container item xs={12}></Grid>
          {/*   <Grid container item xs={12}>


              <Divider className={classes.divider} /></Grid> */}

            <Grid container item xs={12}></Grid>
            {componenteDescripcionCategoria &&
              <>
                <Grid container item xs={12}>
                  <Controller
                    name="descripcion_categoria"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <TextField
                          rows={1}
                          multiline
                          inputProps={{ maxLength: 20 }}
                          size='medium'
                          aria-invalid={true}
                          name="descripcion_categoria"
                          label="Descripcion Categoria"
                          onChange={onChange}
                          value={value}
                        //error={errors.descripç ? true : false}
                        />

                      </>
                    )}
                  />
                </Grid>

                <Grid container item xs={12}></Grid>

                <Grid container item xs={12}>


                  <Divider className={classes.divider} /></Grid>
              </>
            } 


            <Grid container item xs={12}></Grid>

            <Grid container item xs={12}>
              <Controller
                name="descripcion"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <TextField
                      rows={4}
                      multiline
                      size='medium'
                      aria-invalid={true}
                      name="descripcion"
                      label="Descripcion Informe"
                      onChange={onChange}
                      value={value}
                    //error={errors.descripç ? true : false}
                    />

                  </>
                )}
              />
            </Grid>

            <Grid container item xs={12}></Grid>
     {/*        <Grid container item xs={12}>


              <Divider className={classes.divider} /></Grid> */}


              <Grid style={{ marginTop: '20px' }} item xs={12}>

              <Controller
                name="archivo"
                control={control}

                render={({ field: { onChange, value } }) => (
                  <>
                <Controls.Input
                  fullwidth
                  name="archivo"
                  value={value}
                  type="file"
                  onChange={onChange}
                />

                  </>
                )}
              />
     

              </Grid>

          </FormContainer>
          </>
        )
}
export default FormInformes