
import {useContext} from 'react'
import { Grid} from '@material-ui/core';
import FormContainer from '../../Forms/FormContainer';
// Import components
import Controls from '../../Forms/Controls'

// Custom hooks
import {useForm} from '../../../hooks/useForm'
import useSubmit from '../../../hooks/useSubmit';


//Api
import { AnotacionesApi } from '../../../api/AnotacionesApi';

//contexto
import { AppStateContext } from '../../../Contexts/AppStateContextProvider';

//valores iniciales del formulario
const initialFValues = {
  descripcion: '',
  idexpediente: '',
  created_by: localStorage.id,
  updated_by: localStorage.id
  
}




//formulario para cargar una nueva actividad
const FormAnotaciones = ({recargarTabla, edit, anotacionId, descripcion,fnCloseModal=()=>{}}) => {


  const initialEditValues ={
    descripcion: descripcion,
    anotacionId:anotacionId,
    updated_by: localStorage.id
  }

  //funciones para utilizar el formulario traidas desde el hook useForm
  const {
    values,
    handleChangeForm,
    resetForm,
  } = useForm(edit ? initialEditValues:initialFValues);


  //obtencion del id de expediente
  const appStateContext = useContext(AppStateContext);
  values.idexpediente = appStateContext.appState;

  

  //funcion submit del formulario 
  const { formSubmit } = useSubmit( edit ? new AnotacionesApi().updateAnotacionDeExpediente : new AnotacionesApi().addAnotacionDeExpediente, values, recargarTabla,resetForm, fnCloseModal)


  

  return (
      <FormContainer
        LabelButtonSubmit="Guardar" 
        resetForm={resetForm}
        buttonResetForm={false}
        title= {edit? "Editar Anotacion":"Nueva Anotacion"}
        onSubmit={formSubmit}>

          <Grid container item xs={12}>
            <Controls.Input
              label="Observacion..."
              name="descripcion"
              rows={3}
              multiline
              size='medium'
              value={values.descripcion}
              onChange={handleChangeForm}
            />
          </Grid>
          

          

          <Grid container item xs={12}>
            <Grid item xs={12}>
              
            </Grid>
          </Grid>

          

          
      </FormContainer>
  )
}
export default FormAnotaciones