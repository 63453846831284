import React,{useState} from 'react'

import { Grid, TextField,  FormControlLabel, Typography } from '@material-ui/core'
import jwt_decode from "jwt-decode"
import Checkbox from '@material-ui/core/Checkbox';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';


import IconButton from '@mui/material/IconButton';


import ucuHeader from './LogoUCU_SinFondo.png'
import logoUcu50 from './ucu50.png';



//helpers
import history from '../../helpers/history';

//components
import Controls from '../../components/Forms/Controls'
import Header from '../../components/Header/Header';


//hooks
import useSubmit from '../../hooks/useSubmit';
import { useForm } from '../../hooks/useForm';



//api
import { AuthApi } from '../../api/AuthApi';



const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background:'#fff9e0',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#1bbd7e',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25vw',
    


  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerContainer: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: '0',

    height: '10vh',
    width: '100vw',
    backgroundColor: '#fffff9',
    zIndex: 999 ,
    boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
  },
  headerTitle: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
  },
  headerImg: {
    height: '100%',
    width: '100%',
    color: 'black',
    margin: '10px 0px 10px 50px',
  },
  logoUcu50:{
    width: '10vw',
    height: '15vh',
  }
}));



const useSetSessionInLocalStorage = ({access_token}) => {


  //console.log("token", token.access_token);


  localStorage.access_token = access_token
  const user = jwt_decode(access_token);

  console.log("usuario", user)
/* 
  localStorage.id = user.id;
  localStorage.areaid = user.areaid;
  localStorage.name = user.name;
  localStorage.admin = user.admin; */

  localStorage.name = user.name;

  history.push('/expedientes')

}


const initialFValues = {
  username: '',
  password: '',
}

//const theme = createTheme();


const Login = ({ recargarTabla }) => {

  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();

  

  const {
    values,
    handleChangeForm,
    resetForm,
  } = useForm(initialFValues);



  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const { formSubmit } = useSubmit(new AuthApi().loginUser, values,
    recargarTabla, resetForm, useSetSessionInLocalStorage, () => { }, 'Inicio de sesion exitoso')

  return (



    <Grid container className={classes.mainContainer}>

      {/* <Grid className={classes.headerContainer} >
        <Grid item xs={4} ><img className={classes.headerImg} src={ucuHeader} alt='logo ucu' /></Grid>
        <Grid item xs={8} className={classes.headerTitle}><Typography variant="h5">Sistema de Convenios</Typography></Grid>
      </Grid> */}
      <Grid className={classes.headerContainer}>
      <Header menu={false}/>
      </Grid>
      <Grid className={classes.loginContainer} >

        <Grid>
          <img  className={classes.logoUcu50} src={logoUcu50} alt='ucu50anios'/>
        </Grid>
        <Typography component="h1" variant="h5">
          Iniciar Sesion
        </Typography>
        <form onSubmit={formSubmit} className={classes.form} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Usuario"
            name="username"
            value={values.username}
            onChange={handleChangeForm}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            value={values.password}
            onChange={handleChangeForm}
            type={showPassword ? 'text': 'password'}
        InputProps={{
          endAdornment: <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </InputAdornment>}
        }
          />
          <Grid className={classes.submit}>
          <Controls.Button
            type="submit"
            fullWidth
            variant="opcional"
            text='Iniciar Sesion'

          />
          </Grid>

        </form>
      </Grid>

    </Grid>



  )
}
export default Login