import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';





// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontSize: 12,
    fontWeight:0.5
  },
  mainContainer:{
    margin: 30,
    display:'flex',
    justifyContent: 'flex-start',
  },
  encabezado:{
    display:'flex',
    flexDirection: 'row',
    justifyContent:'space-around',
    padding:'10px 0 10px 0' ,
    border: 0.5
  },
  backgroundImg:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    alignSelf:'center',
    height:'10vh',
    width: '100%',
    backgroundColor: '#f1db14',
  },
  img:{
    alignSelf:'center',
    height:'100%',  
    width: '60%',

  },
  caratula:{
    width:'100%',
    display: 'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    padding: 30,
    border: 0.5
  },
  objeto:{
    width:'100%',
    height: '20vh',
    maxHeight:'100%',
    display: 'flex',
    justifyContent:'flex-start',
    alignItems:'flex-start',
    padding: 10,
    border: 0.5
  },
  actividades:{
    marginTop: 10,
  },
  containerActividades:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems: 'flex-start',

  },


  

});


/* Promise.resolve(promise).then(function(buffer){
     console.log(buffer);
}); */


// Create Document Component




const  GeneratePdf =({datosPdf,actividadesExpediente})=>(


   <Document>
    
    <Page size="A4" style={styles.page}>

      <View style={styles.mainContainer}>

        <View style={styles.backgroundImg}></View>
      
      <Text style={{alignSelf:'center', marginBottom: '10px', marginTop: '10px'}}>INFORME DE CONVENIO</Text>


      <View style={styles.encabezado}>

        <Text>Numero: {datosPdf.numero}</Text>
        <Text>Año: {datosPdf.anio}</Text>
        <Text>Vigencia de: {datosPdf.vigenciade}</Text>
        <Text>A: {datosPdf.a}</Text>
      </View>

      <View style={styles.caratula}>

        <Text style={{position: 'absolute', left:'10',top:'10px'}} >{datosPdf.caratula}</Text>

      </View>
      <View style={styles.objeto}>


        <Text style={{position: 'absolute', left:'10',top:'10px'}}>
          OBJETO: {datosPdf.objeto}
         </Text>

      </View>

      
      
      {/* <Text style={{alignSelf:'center', margin: '20px 0 20px 0'}}>Actividades</Text>
      <View style={styles.containerActividades}> 

      {actividadesExpediente.map((actividad)=><View style={styles.actividades}>
      <View style={{marginLeft: 2,marginBottom: 10, alignSelf: 'flex-start'}}><Text>{actividad.created_at}      {actividad.caratula}</Text></View>
      <View style={{margin: 2, alignSelf: 'flex-start'}}><Text >{actividad.descripcion}</Text></View></View>
      )}
       </View> */}
      
      </View>
    </Page>
  </Document>

)


export default GeneratePdf;