import React,{useEffect} from 'react'
import history from "../../helpers/history";
import style from './header.module.css'
import AccountMenu from './AccountMenu'


import { UserApi } from '../../api/UserApi';




import ucuHeader from './ucu-completo.svg'

const goToHomePage=async()=>{
    await new UserApi().isSuperAdmin(localStorage.id)
    .then(resp=>{
      localStorage.setItem('admin',resp.data[0].isAdmin);
      history.push('/expedientes')
      
      
    })
    .catch(err=>{
        history.push('/expedientes')
      console.log('Ha ocurrido un error')
    })
    
}

export default function Header({menu=true}) {

 
    return (
        //#b0d5f8 color
        //#80c1ff color
        //#f4f241 color
        <div className={style.headerContainer} >
            <img onClick={goToHomePage} className={style.headerImg} src={ucuHeader} alt='logo ucu'/>
            <div className={style.headerTitle} >Registro de convenios (UCU)</div>
            <div className={style.headerMenu}>{menu &&<AccountMenu  />}</div>
        </div>
    )
}
