import AxiosInstance from "../hooks/axiosInstace";



//clase para llamar a los metodos del modulo seguimiento
export class TrayectoriasApi {

    enviarExpediente(body){
        return AxiosInstance.post(`seguimiento`,body)
    }

    getTrayectoriaDeExpediente(expedienteId){
        
        return AxiosInstance.get(`seguimiento/${expedienteId}`);
    }

    

}