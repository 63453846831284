import { useState } from 'react'
import { useSnackbar } from "notistack";


import { tokenIsExpired } from '../../helpers/authToken';

import history from '../../helpers/history';
import { decodeToken } from '../../helpers/authToken';


//contexto
//import { AppStateContext } from '../../Contexts/appStateContextProvider';



//components
import Table from '../../components/Table/CustomTable'
import Modal from '../../components/Modals/Modal'
import Trayectorias from '../../components/ModaTable/Trayectorias/Trayectorias'
import Anotaciones from '../../components/ModaTable/Anotaciones/Anotaciones';
import EnviarConvenio from './EnviarConvenio';
import Archivos from '../../components/ModaTable/Archivos/Archivos'
import LayoutTest from '../../Layout/LayoutTest'
import FilterForm from '../../components/Forms/FilterForm';
import ModalDialog from '../../components/Modals/ModalDialog';
import ActionMenu from '../../components/Menu/ActionMenu'
import FormConvenios from './FormConvenios/FormConvenios';
import LinkContainer from '../../components/LinkContainer/LinkContainer';
import SearchBarMultiple from '../../components/SearchBar/SearchBarMultiple';
import DetalleConvenio from '../../components/DetalleConvenio/DetalleConvenio';
import EditarConvenio from './FormConvenios/EditarConvenio';

//hook
import useCargarTabla from '../../hooks/useCargarTabla';
import useModal from '../../hooks/useModal';
import useSearchFilter from '../../hooks/useSearchFilter';



//api
import { ExpedientesApi } from '../../api/ExpedientesApi'

//helper
//import { removeStoredAuthToken } from '../../helpers/authToken';


const logout = () => {

  //if (tokenIsExpired()) history.push('/');

}




const getConvenio = async (convenioId, setData) => {

  new ExpedientesApi().getExpedientePorId(convenioId)
    .then(resp => {
      console.log('s: ', resp)
      setData(resp.data[0])
    })

}

//abrir link de google docs del expediente
const openLinkInANewTab = async (data, setLink, modal) => {

  console.log('openLink data: ', data)


  await new ExpedientesApi().getDocumentLink(data.id)
    .then(resp => {

      console.log(resp.data[0]);
      /*  let a = document.createElement('a');
       let link = resp.data[0].documentlink;
       a.target = '_blank'
       a.href = link;
       a.click(); */
      setLink(resp.data[0].documentlink)
    })




}

const finalizarConvenio = async(idexpediente,recargarTabla,enqueueSnackbar) =>{

  await new ExpedientesApi().finalizarExpediente(idexpediente)
  .then((response) => {

    enqueueSnackbar("Convenio finalizado con exito", { variant: "success" });
    recargarTabla();
  })
  .catch((err) => {
    console.log('error: ',err.response.data.message);
    enqueueSnackbar(err.response.data.message? err.response.data.message: "No se pudo finalizar el convenio", { variant: "error" });
  });


}

const aprobarConvenio = async (idexpediente,numero,recargarTabla,enqueueSnackbar) =>{

  console.log("numero:", numero );

  await new ExpedientesApi().aprobarExpediente(idexpediente,numero)
  .then((response) => {

    enqueueSnackbar(`El convenio ${numero} ha sido aprobado`, { variant: "success" });
    recargarTabla();
  })
  .catch((err) => {
    console.log('error: ',err.response.data.message);
    enqueueSnackbar(err.response.data.message? err.response.data.message: "No se pudo aprobar el convenio", { variant: "error" });
  });

}

const mandarADictamen = async (idexpediente,numero,recargarTabla,enqueueSnackbar) =>{

  console.log("numero:", numero );

  await new ExpedientesApi().mandarADictamen(idexpediente,true)
  .then((response) => {

    enqueueSnackbar(`El convenio ${numero} se mando a fase dictamen`, { variant: "success" });
    recargarTabla();
  })
  .catch((err) => {
    console.log('error: ',err.response.data.message);
    enqueueSnackbar(err.response.data.message? err.response.data.message: "No se pudo mandar a dictamen", { variant: "error" });
  });

}


//pagina de convenios en gestion
export default function Convenios() {

  const { enqueueSnackbar } = useSnackbar();

  //modales de las opciones del expediente
  const modalTrayectorias = useModal();
  const modalAnotaciones = useModal();
  const modalArchivos = useModal();
  const modalEnviar = useModal();
  const modalFilter = useModal();
  const modalGoogleDocsLink = useModal();
  const modalDetalleConvenio = useModal();
  const modalFinalizarConvenio = useModal();
  const modalEditarConvenio = useModal();
  const modalAprobarConvenio = useModal();
  const modalMandarADictamen = useModal();


  //const [dataExpediente, setDataExpediente] = useState('');
  const [expedienteId, setExpedienteId] = useState('');
  const [convenio, setConvenio] = useState('');
  const [linkGoogleDocs, setLinkGoogleDocs] = useState('');


  //modal para nuevo expediente
  const modalNuevoExpediente = useModal();

  //obtener el contexto de la aplicacion

  //const appStateContext = useContext(AppStateContext);



  //carga de datos de convenios en gestion con id del usuario
  const { dataTable, loading, recargarTabla } = useCargarTabla(new ExpedientesApi().getExpedientesEnGestion, decodeToken('id'))


  const {
    filterTable,
    setSearchText,
    showFilter,
    changeBuscar,
    filterLoading,
    setShowFilter,
  } = useSearchFilter((searchText) => new ExpedientesApi()
    .getExpedientesEnGestion(decodeToken('id'), searchText), recargarTabla)


  //opciones del expediente
  let options = [
    (decodeToken('id') === '2') ? { title: "Finalizar Convenio", icon: 'finish', action: (data) => {
      setExpedienteId(data.id);
      modalFinalizarConvenio.openModal()} } : '', 
      { title: "Enviar", icon: 'send', action: (data) => {
        console.log('data: ',data)
        setExpedienteId(data.id);
        modalEnviar.openModal()} },
   (decodeToken('id') === '2' || decodeToken('id') === '1')?{title:"Aprobar Convenio",icon:'true',action:(data)=>{
    modalAprobarConvenio.openModal()
    setConvenio(data);
   }}:'',
   (decodeToken('id') === '2' || decodeToken('id') === '24') ? { title: "Mandar a Dictamen", icon: 'flechaDerecha', action: (data) => {
    
    modalMandarADictamen.openModal();
    setConvenio(data); } } : '', 
    {
      title: "Ver Detalle Convenio", icon: 'view', action: (data) => {
        modalDetalleConvenio.openModal();
        getConvenio(data.id, setConvenio);
      }
    },
    { title: "Anotaciones", icon: 'note', action: () => modalAnotaciones.openModal() }
    ,
    { title: "Participantes", icon: 'users', action: () => modalTrayectorias.openModal() },
    {
      title: "Link Google Docs", icon: 'link', action: (data) => {
        modalGoogleDocsLink.openModal();
        openLinkInANewTab(data, setLinkGoogleDocs)
      }
    },
    {
      title: "Ver Convenio marco", icon: 'view', action: (data) => {
        modalDetalleConvenio.openModal();
        getConvenio(data.conveniomarcoid, setConvenio);
      }
    },
    { title: "Archivos", icon: 'file', action: (data) => {
      setConvenio(data)
      modalArchivos.openModal()} },

  ]






  //columnas de la tabla de convenios en gestion
  const columns = [
    { title: 'Numero', name: 'numero' },
    { title: 'Año', name: 'anio' },
    { title: 'Tipo de Convenio', name: 'tipoconvenio' },
    { title: 'Caratula', name: 'caratula' },

    { title: 'Creador', name: 'creador' },
    {title: 'Aprobado',name: 'visado',type: 'boolean', align: 'center'},
    { title: 'Fecha Aprobado', name: 'fechaAprobado' },
    
    { title: 'Vencimiento', name: 'a' },

  ]

  //botones de nuevo expediente y log out
  const buttons = [

    {
      icon: 'document',
      action: () => {
        //alert("Boton deshabilitado!")
        modalNuevoExpediente.openModal()
      },
      label: 'Nuevo convenio'

    }




  ]

  //acciones del expediente
  const actions = [
    {
      icon:'editar',
      action: (data)=>{
        console.log('data convenio editar: ', data)
        modalEditarConvenio.openModal()
        setConvenio(data);
      }
    }
  ]


  return <LayoutTest


    filterForm={

      <SearchBarMultiple setSearchText={setSearchText}
        changeBuscar={() => changeBuscar()}
        closeModal={() => setShowFilter(false)} />
    }

    buttons={buttons}
    appBar={true}
    header={true}
  >


    {
      modalGoogleDocsLink.modal &&
      <ModalDialog

        title='Link del Convenio en Google Docs'
        closeModal={() => modalGoogleDocsLink.closeModal()}
        active={modalGoogleDocsLink.modal}
        hiddenButton={true}
        accionAceptar={() => { }}
      >
        <LinkContainer expedienteId={expedienteId} linkGoogleDocs={linkGoogleDocs} />

      </ModalDialog>
    }

    {
      modalDetalleConvenio.modal &&
      <Modal
        width={'45vw'}
        closeModal={() => modalDetalleConvenio.closeModal()}
        active={modalDetalleConvenio.modal}
      >
        <DetalleConvenio convenio={convenio} />
      </Modal>
    }


    {
      modalFilter.modal &&
      <ModalDialog
        width={'500px'}
        height={'350px'}

        closeModal={() => modalFilter.closeModal()}
        active={modalFilter.modal}
        hiddenButton={true}
        accionAceptar={() => { }}
        fondo='none'

      >
        <FilterForm
          setSearchText={setSearchText}
          changeBuscar={() => changeBuscar()}
          closeModal={() => modalFilter.closeModal()}

        //search={()=>setBuscar(true)}

        />


      </ModalDialog>}

    {modalFinalizarConvenio.modal &&
      <ModalDialog



        labelButton="Finalizar Convenio label"
        title='Finalizar Convenio'
        closeModal={() => modalFinalizarConvenio.closeModal()}
        active={modalFinalizarConvenio.modal}
        accionAceptar={
          () => {
            finalizarConvenio(expedienteId,recargarTabla,enqueueSnackbar);
            modalFinalizarConvenio.closeModal();
          }}
      //fondo='none'

      >
        {console.log("estoy activo pero escondido")}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>¿Esta seguro de finalizar este convenio?</div>
      </ModalDialog>
    }


{modalAprobarConvenio.modal &&
      <ModalDialog



        labelButton="Aprobar Convenio label"
        title='Aprobar Convenio?'
        closeModal={() => modalAprobarConvenio.closeModal()}
        active={modalAprobarConvenio.modal}
        accionAceptar={
          () => {
            aprobarConvenio(expedienteId,convenio.numero,recargarTabla,enqueueSnackbar);
            modalAprobarConvenio.closeModal();
          }}
      //fondo='none'

      >
        {console.log("estoy activo pero escondido")}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>¿Esta seguro de aprobar este convenio?</div>
      </ModalDialog>
    }

{modalMandarADictamen.modal &&
      <ModalDialog



        labelButton="Mandar a dictamen label"
        title='¿Mandar a fase de dictamen?'
        closeModal={() => modalMandarADictamen.closeModal()}
        active={modalMandarADictamen.modal}
        accionAceptar={
          () => {
            mandarADictamen(expedienteId,convenio.numero,recargarTabla,enqueueSnackbar);
            modalMandarADictamen.closeModal();
          }}
      //fondo='none'

      >
        {console.log("estoy activo pero escondido")}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>¿Esta seguro de mandar a dictamen este convenio?</div>
      </ModalDialog>
    }


    {
      modalEnviar.modal &&
      <ModalDialog
       
        closeModal={() => modalEnviar.closeModal()}
        active={modalEnviar.modal}
        hiddenButton={true}

      >
        <EnviarConvenio recargarTabla={recargarTabla} idexpediente={expedienteId} fnCloseModal={() => modalEnviar.closeModal()} />

      </ModalDialog>
    }


    {
      modalNuevoExpediente.modal &&
      <Modal
        width={'44vw'}
        labelButton="Nuevo Expediente label"
        closeModal={() => modalNuevoExpediente.closeModal()}
        active={modalNuevoExpediente.modal}

      >
        <FormConvenios recargarTabla={recargarTabla} fnCloseModal={() => modalNuevoExpediente.closeModal()}></FormConvenios>

      </Modal>
    }
     {
      modalEditarConvenio.modal &&
      <ModalDialog
        
        hiddenButton={true}
        labelButton="Editar convenio label"
        closeModal={() => modalEditarConvenio.closeModal()}
        active={modalEditarConvenio.modal}

      >
        <EditarConvenio recargarTabla={recargarTabla} convenio={convenio} fnCloseModal={() => modalEditarConvenio.closeModal()}></EditarConvenio>

      </ModalDialog>
    }
    {
      modalTrayectorias.modal &&
      <Modal
        width={'45vw'}
        labelButton="Trayectorias label"
        closeModal={() => modalTrayectorias.closeModal()}
        active={modalTrayectorias.modal}

      >
        <Trayectorias expedienteId={expedienteId}></Trayectorias>

      </Modal>
    }

    {
      modalArchivos.modal &&
      <Modal
        width={'65vw'}
        labelButton="Archivos label"
        closeModal={() => modalArchivos.closeModal()}
        active={modalArchivos.modal}

      >
        <Archivos expedienteId={expedienteId} isFinalizado={false} numeroDeConvenio={convenio.numero}></Archivos>

      </Modal>
    }

    {
      modalAnotaciones.modal &&
      <Modal
        width={'55vw'}
        labelButton="Actividades label"
        closeModal={() => modalAnotaciones.closeModal()}
        active={modalAnotaciones.modal}

      >
        <Anotaciones expedienteId={expedienteId} />

      </Modal>
    }
    <Table
      tableData={showFilter ? filterTable : dataTable}
      RowMenu={(data) => {
        logout();
        return <ActionMenu data={data} setExpedienteId={setExpedienteId} options={options} />
      }}
      collapseTable={false}
      columns={columns}
      filterTable={filterTable.length}
      actions={decodeToken('admin')===1?actions:[]}
      filterLoading={filterLoading}
      loading={loading}
    />
  </LayoutTest>
}

