import { useState, useCallback } from "react"
import { useSnackbar } from 'notistack';


//hook submit de un formulario 
const useSubmit = (action, dataSubmit, recargarTabla = () => { },resetForm=()=>{}, funcSuccess=()=>{}, 
funcError=()=>{}, textSucces = "Listo!", textError) => {

  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false)
  const formSubmit = useCallback((event) => {
    event.preventDefault()

    setLoading(true)

    action(dataSubmit)
      .then(resp => {
        //console.log("respuesta useSubmit: ",resp.data)
        recargarTabla()
        funcSuccess(resp.data)
        setLoading(false)
        enqueueSnackbar(textSucces, { variant: 'success' })
        resetForm()
        
      })

      .catch(err => {
        //console.log(err.response.data.message);
        funcError()
        enqueueSnackbar(textError?textError: err.response?err.response.data.message:'Ha ocurrido un error', { variant: 'error' })
        console.log("ERROR ENVIAR DATOS DEL FORMULARIO ", err)
      })

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataSubmit)])

  

  return {
    formSubmit,
    loading,
  }
}
export default useSubmit