import { useState } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import FormContainer from "../../../components/Forms/FormContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useMediaQuery from '@mui/material/useMediaQuery';
import ButtonIcon from '../../../components/Buttons/ButtonIcon';

import FormAsignarConvenios from '../../AsignarConvenios/FormAsignarConvenios';


import useModal from '../../../hooks/useModal';
import ModalDialog from '../../../components/Modals/ModalDialog';


//refactorizar utils
//utils

import { decodeToken } from '../../../helpers/authToken';


// Import components
import Controls from "../../../components/Forms/Controls";



//Api
import { ExpedientesApi } from "../../../api/ExpedientesApi";


const schema = yup
  .object({
    objeto: yup.string().required("Este campo es requerido")
  })
  .required();


export default function EditarConvenio({ convenio, fnCloseModal = () => { }, recargarTabla = () => { } }) {

  const matchFullHD = useMediaQuery('(min-width:2000px)');

  const modalAsignarTipos = useModal();

  const { enqueueSnackbar } = useSnackbar();

  console.log('convenio dictamen: ', convenio.dictamen);

  const vigenciadeSplit = convenio.vigenciade.split('/')

  const [minDateVencimiento, setMinDateVencimiento] = useState(convenio.vigenciade ? new Date(vigenciadeSplit[2], vigenciadeSplit[1] - 1, vigenciadeSplit[0]) : new Date('1994-01-01'));

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      objeto: convenio.objeto,
      caratula: convenio.caratula,
      vigenciade: convenio.vigenciade ? convenio.vigenciade : null,
      a: convenio.a ? convenio.a : null,
      visado: convenio.visado?convenio.visado:false,
      dictamen: convenio.dictamen? convenio.dictamen: false,
      updated_by: decodeToken('id'),
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {

    data.expedienteId = convenio.id;

    console.log('data actualizar: ', data);

    await new ExpedientesApi()
      .updateExpediente(data)
      .then((response) => {
        enqueueSnackbar("Convenio actualizado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        enqueueSnackbar("No se ha podido actualizar el convenio", { variant: "error" });
      });

    //alert(JSON.stringify(data))

  }


  return (
    <FormContainer

      LabelButtonSubmit="Guardar"
      buttonResetForm={false}
      //resetForm={resetForm}
      title={"Editar Convenio"}
      onSubmit={handleSubmit(onSubmit)}
    >

{
      modalAsignarTipos.modal && 
      <ModalDialog
      hiddenButton={true}
      labelButton="Asignar Tipos a convenios"
      closeModal={() => modalAsignarTipos.closeModal()}
      active={modalAsignarTipos.modal}

    >
      <FormAsignarConvenios recargarTabla={recargarTabla} caratulaConvenio={convenio.caratula}  
      idexpediente={convenio.id} fnCloseModal={()=>modalAsignarTipos.closeModal()} tipoconvenioid={convenio.tipoconvenioid}/>

    </ModalDialog>
    }

  {/*     <Grid item >
      <Paper style={{   padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            background: "#FFF",
            borderRadius: 5,
            marginBottom: '10px',
            }} 
            >
        <ButtonIcon
          label="Editar Tipo de convenio"
          endIcon={CheckBoxIcon}
          onClick={modalAsignarTipos.openModal}
        />
        </Paper>
      </Grid> */}
        <Grid item xs={12} >
          <Controller
            name="caratula"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField

                required={true}
                name="caratula"
                size={matchFullHD ? 'medium' : 'small'}
                label="Caratula"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid container item xs={12}></Grid>

        <Grid container item xs={12}>


          <Controller
            name="objeto"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  rows={3}
                  multiline
                  size='medium'
                  aria-invalid={true}
                  name="objeto"
                  label="Objeto"
                  onChange={onChange}
                  value={value}
                  error={errors.objeto ? true : false}
                />

              </>
            )}
          />
        </Grid>


        <Grid container item xs={12}></Grid>




        <Grid container alignItems="center" item xs={12}>
          <Grid item xs={12}>
            <Controller
              name="vigenciade"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Controls.DateField
                  name="vigenciade"
                  title="Fecha inicio"
                  
                  value={value}
                  size={'medium'}
                  minDate={'1/01/1994'}
                  onChange={(event) => {
                    console.log('event value: ', event.target.value)
                    var dateParts = event.target.value.split("/");
                    setMinDateVencimiento(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
                    onChange(event)
                  }}
                />
              )}
            />
          </Grid>
        </Grid>


        <Grid container item xs={12}></Grid>

        <Grid container alignItems="center" item xs={12}>
          <Grid item xs={12}>
            <Controller
              name="a"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Controls.DateField
                  name="a"
                  title="Fecha fin"
                  
                  value={value}
                  size={'medium'}
                  minDate={minDateVencimiento}
                  onChange={(event) => {
                    
                    onChange(event)
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

       {/*  <Grid container item xs={12}>
        <Grid item xs={12}>
          <Controller
            name="a"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Controls.DateField
                size='medium'
                name="a"
                title="Fecha fin"
                value={value}
                minDate={minDateVencimiento}
                onChange={(event) => {
                  
                  onChange(event)
                }} />
            )}
          />
          </Grid>

        </Grid> */}

        <Grid container alignItems="center" item xs={12}>

        <Controller
            name="visado"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Controls.Checkbox
                size='small'
                name="visado"
                label="Aprobado"
                value={value}
                onChange={(event) => {
                  /* console.log('minDateVencimiento ',minDateVencimiento);
                  console.log('event: ',event); */
                  onChange(event)
                }} />
            )}
          />
          </Grid>

          <Grid container alignItems="center" item xs={12}>

        <Controller
            name="dictamen"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Controls.Checkbox
                size='small'
                name="dictamen"
                label="Dictamen"
                value={value}
                onChange={(event) => {
                
                  onChange(event)
                }} />
            )}
          />
          </Grid>


    </FormContainer>
  );
}
