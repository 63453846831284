
import {useContext} from 'react'
import { Grid} from '@material-ui/core';
//import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField'
import FormContainer from '../../Forms/FormContainer';
// Import components
import Controls from '../../Forms/Controls'

// Custom hooks
import {useForm} from '../../../hooks/useForm'
import useSubmit from '../../../hooks/useSubmit';


//Api
import { ActividadesApi } from '../../../api/ActividadesApi';

//contexto
import { AppStateContext } from '../../../Contexts/AppStateContextProvider';

//valores iniciales del formulario
const initialFValues = {
  caratula: '',
  descripcion: '',
  fecha: '',
  fechafin: '',
  idexpediente: '',
  created_by: localStorage.id,
  updated_by: localStorage.id
  
}


//formulario para cargar una nueva actividad
const FormActividad = ({recargarTabla , fnCloseModal=()=>{}}) => {

  //funciones para utilizar el formulario traidas desde el hook useForm
  const {
    values,
    handleChangeForm,
    resetForm,
  } = useForm(initialFValues);


  //obtencion del id de expediente
  const appStateContext = useContext(AppStateContext);
  values.idexpediente = appStateContext.appState;

  

  //funcion submit del formulario 
  const { formSubmit } = useSubmit( new ActividadesApi().addActividadDeExpediente, values, recargarTabla,resetForm, fnCloseModal)


  

  return (
      <FormContainer
        width="45vw"
        LabelButtonSubmit="Guardar" 
        buttonResetForm={false}
        title= "Nueva Actividad"
        onSubmit={formSubmit}>
          <Grid container item xs={12}>
            <Controls.Input
              name="caratula"
              label="Caratula"
              value={values.caratula}
              onChange={handleChangeForm}
            />
          </Grid>

          <Grid container item xs={12}>
            {/* <Controls.Input
              label="Descripcion"
              name="descripcion"
              value={values.descripcion}
              onChange={handleChangeForm}
            /> */}

              <TextField
                  rows={4}
                  multiline
                  size='medium'
                  aria-invalid={true}
                  name="descripcion"
                  label="Descripcion"
                  onChange={handleChangeForm}
                  value={values.description}
                  //{...register('objeto')}
                  //error={errors.objeto ? true : false}
                //helperText={errors.objeto?.message}
                />

        {/* <TextareaAutosize
          maxRows={4}
          aria-label="maximum height"
          name="descripcion"
          value={values.descripcion}
          onChange={handleChangeForm}
          style={{ width: '100%',height: 200 }}
        /> */}

          </Grid>
          

          

          <Grid container item xs={12}>
            <Grid item xs={12}>
              
            </Grid>
          </Grid>

          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Controls.DateField
                name="fecha"
                value={values.fecha}
                onChange={handleChangeForm}
                title="Fecha"
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Controls.DateField
                name="fechafin"
                value={values.fechafin}
                onChange={handleChangeForm}
                title="Fecha Fin"
              />
            </Grid>
          </Grid>

          
      </FormContainer>
  )
}
export default FormActividad