import AxiosInstance from "../hooks/axiosInstace";


export class UserApi{

    createUser(body){
        return AxiosInstance.post(`user`,body)
    }

    getAllUsers(userId=0){
        return AxiosInstance.get(`user?getby=${userId}`);
    }

    getAllDataUsers(){
        return AxiosInstance.get(`user/userdata`);
    }

    getArea(userId){
        return AxiosInstance.get(`user/area/${userId}`)
    }

    updateUser(body){
        let userId=body.userId;
        delete  body.userId
        return AxiosInstance.put(`user/${userId}`,body)
    }

    updatePassword(body){
        let userId=body.userId;
        delete  body.userId
        return AxiosInstance.put(`user/updatePassword/${userId}`,body);
    }

    isSuperAdmin(userId){
        return AxiosInstance.get(`user/is_super_admin/${userId}`);
    }
}