import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles(() =>
    createStyles({
       
        checkbox:{
            fontWeight: 400,
            color: '#395596',
            padding: '8px 15px',
            '&:hover': {
                cursor: 'pointer',
            }
        }
    })
)

export default function CheckBox({check=false, onClick = ()=>{}}) {

    const classes = useStyles();

    return (
        
            
            
            <div 
            onClick={onClick}
            className={classes.checkbox}
            >
                {
                    check
                        ? <CheckBoxIcon />
                        : <CheckBoxOutlineBlankIcon />
                }
            </div>
        
        
    )
}
