import React,{ useState} from 'react'
import { useSnackbar } from "notistack";




//componentes
import LayoutTest from '../../../Layout/LayoutTest';
import FormActividad from '../Actividades/FormActividad';
import Table from '../../Table/CustomTable';
import Modal from '../../Modals/Modal';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';


//Api
import {InformesApi} from '../../../api/InformesApi';
import FormInformes from './FormInformes';


const downloadFile = async (data,enqueueSnackbar) => {

  console.log("datos: ",data);


  const archivo = data.archivo;
  const informe_id = data.id;

  console.log('archivo1: ',archivo);

  if (archivo) {

    let extension = archivo.split('.')[1]

    const body = { filename: archivo,informe_id: informe_id }


    console.log('body: ',body)
    const archivoDescargardo = new InformesApi().downloadFile

    await archivoDescargardo(body)
    .then(response => {
    // Obtener el encabezado 'Content-Disposition'
    console.log('headers: ',response.headers);
    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];

    console.log('contentDisposition: ',contentDisposition)

    // Extraer el nombre del archivo del encabezado
    let fileName = 'archivo_descargado'; // Nombre predeterminado si no se encuentra el encabezado

    if (contentDisposition) {
      
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

      console.log('fileNameMatch: ',fileNameMatch)
      if (fileNameMatch.length > 1) {
        fileName = fileNameMatch[1];
      }
    }
      

      const archivo = response.data.archivo.data;

      

      const archivoOriginal = archivo

      const byteArray = new Uint8Array(archivo).buffer;

      console.log('archivo: ',byteArray)
      const blob = new Blob([byteArray], { type: contentType,download: fileName });
      if (extension === 'pdf') {

        var file = blob;
        var fileURL = URL.createObjectURL(file);
        //var win = window.open();
        const win = window.open(fileURL, '_blank');
  
        // Esperar a que la nueva pestaña cargue el contenido
        win.onload = () => {
          // Crear un enlace de descarga en la nueva pestaña
          const downloadLink = win.document.createElement('a');
          downloadLink.href = fileURL;  // Enlace al blob
          downloadLink.download = fileName;  // Usar el nombre de archivo original para la descarga
          downloadLink.innerHTML = 'Descargar archivo PDF';
          downloadLink.style.display = 'block';
          downloadLink.style.marginTop = '20px';
          
          // Añadir el enlace de descarga al cuerpo del documento
          win.document.body.appendChild(downloadLink);
        };
      } else {
     

      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      }

    })
      .catch(err =>{
        const errorMessage = err.response && err.response.data && err.response.data.message 
        ? err.response.data.message 
        : 'Ocurrió un error inesperado';
    
        enqueueSnackbar(errorMessage, { variant: "error" });
      })


      console.log('asddas')

  } else {
    enqueueSnackbar('No hay archivo', { variant: "error" });
  }


}


//Modal de Informes de expediente recibe el id de expediente y devuelve el componente renderizado
export default function Informes({expedienteId,esCreador}) {
    
  //carga de actividades utilizando el hook useCargarTabal()
    const {dataTable,loading,recargarTabla}= useCargarTabla( new InformesApi().getInformesDeExpediente,expedienteId)

    //estado de modal para mostrar el form de nueva actividad
    const [modalNuevoInforme, setModalNuevoInforme] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const closeModal = () =>setModalNuevoInforme(false)


    //columnas de la tabla Actividades
    const columns = [
        { title: 'Fecha', name:'fecha_informe'},
        { title: 'Descripcion', name:'descripcion'},
        {title: 'Categoria',name:'nombrecategoria'},
        {title: 'Usuario',name:'usuario'}
       
      ]

      //botones de Actividades
    const buttons = [
        {
          icon: 'noteadd',
          action: () => setModalNuevoInforme(!modalNuevoInforme),
          label: 'Nuevo'
    
        },
        
      ]


      const actions = [
        {
          icon: "download",
          action: (data) => downloadFile(data, enqueueSnackbar),
        },
      ]
    

    return (
        <LayoutTest
        
        buttons={esCreador?buttons:[]}
    >
      {
        <Modal
        width={'55vw'}
        labelButton="informes label"
        closeModal={() => setModalNuevoInforme(!modalNuevoInforme)}
        active={modalNuevoInforme}
        fondo='none'

      >
        <FormInformes recargarTabla={recargarTabla} fnCloseModal={closeModal}></FormInformes>
        
      </Modal>
      }
      <Table
        tableData={dataTable}
        //RowMenu={()=><ActionMenu onClick={()=>{}} options={options}/>}
        collapseTable={false}
        columns={columns}
        actions={actions}
        loading={loading}
        
        pageValue={0.6}
      />
    </LayoutTest>
    )
}
