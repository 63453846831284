import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core/";
import PropTypes from "prop-types";
import Tooltip from '@mui/material/Tooltip';


import Icons from "../Icons";

const propTypes = {
  endIcon: PropTypes.string,
  startIcon: PropTypes.string,
  label: PropTypes.string,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
  typeButton: PropTypes.string,
  centerIcon: PropTypes.string,
};

const useStyles = makeStyles(() =>
  createStyles({
    default: {
      background: "none",
      boxShadow: "none",
      color: "#6E6893",
      textTransform: "capitalize",
      minWidth: "max-content",
      paddingLeft: 4,
      paddingRight: 4,
    },
    hover: {
      "&:hover": {
        background: "#d9d5e8",
        boxShadow: '0px 8px 15px rgb(11 38 173 / 20%)',
      },
    },
  })
);

const ButtonIcon = ({
  centerIcon = "null",
  startIcon = "null",
  endIcon = "null",
  label,
  typeButton = "default",
  hover = false,
  onClick,
  tooltipTitle = null,
  textAlign = "",
  width = "auto",
  ...other
}) => {
  const classes = useStyles();
  return (
    <>

    {tooltipTitle ? <Tooltip
    title={tooltipTitle} arrow
    >

   
    <Button
      style={{
        width: `${width}`,
        display: "flex",
        justifyContent: `${
          textAlign === "right"
            ? "flex-end"
            : textAlign === "left"
            ? "flex-start"
            : "center"
        }`,
      }}
      onClick={onClick}
      className={`${hover && classes.hover} ${classes[typeButton]}`}
      endIcon={<Icons type={endIcon} />}
      startIcon={<Icons type={startIcon} />}
    >
      {!other.children &&
        (label ? (
          `${label[0].toUpperCase() + label.slice(1)}`
        ) : (
          <Icons type={centerIcon} />
        ))}
      {other.children && other.children}
    </Button>
    </Tooltip>: 
    
    <Button
    style={{
      width: `${width}`,
      display: "flex",
      justifyContent: `${
        textAlign === "right"
          ? "flex-end"
          : textAlign === "left"
          ? "flex-start"
          : "center"
      }`,
    }}
    onClick={onClick}
    className={`${hover && classes.hover} ${classes[typeButton]}`}
    endIcon={<Icons type={endIcon} />}
    startIcon={<Icons type={startIcon} />}
  >
    {!other.children &&
      (label ? (
        `${label[0].toUpperCase() + label.slice(1)}`
      ) : (
        <Icons type={centerIcon} />
      ))}
    {other.children && other.children}
  </Button>

    }
    </>
  );
};

ButtonIcon.propTypes = propTypes;

export default ButtonIcon;
