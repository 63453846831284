import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Controls from '../Forms/Controls';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles, createStyles } from '@material-ui/core/styles';



export default function ModalDialog({ title, active, closeModal, accionAceptar = () => { }, children,
  labelButtonSubmit = "Aceptar", hiddenButton = false, width = '500px', height = '100%',type='button' }) {


  const useStyles = makeStyles((theme) =>
    createStyles({
      modalContainer: {
        top: '50%',
        right: '0',
        position: 'fixed',
        zIndex: 2,

        
        
      },
      modal: {
        width: width,
        overflowY: 'hidden',
        height: height,
        
        
      },
      cerrar: {
        position: 'absolute',
        zIndex: 1000,
        top: '5px',
        right: '0px',
        cursor: 'pointer'

      }
    }));

  const classes = useStyles();
  return (
    <Dialog
      open={active}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //style={{ width: width, height: height,display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      className={classes.modalContainer}
    >
      <DialogTitle >
        <Typography variant="h5" id="alert-dialog-title">{title}</Typography>
      </DialogTitle>
      <div onClick={closeModal} className={classes.cerrar}>
        <HighlightOffIcon fontSize="large" color="secondary" />
      </div>
      <DialogContent
        //style={{ width: width, height: height,display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        className={classes.modal}
      >
        {children}
      </DialogContent>
      {!hiddenButton &&
        <DialogActions >

          <Controls.Button
            text={labelButtonSubmit}
            onClick={accionAceptar}
            variant="primary" />
          <Controls.Button
            text="Cancelar"
            onClick={closeModal}
            variant="secondary" />

        </DialogActions>}
    </Dialog>
  );
}
