import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
//Components
import { Grid, Paper } from '@material-ui/core';
import ButtonIcon from '../components/Buttons/ButtonIcon';
import Header from '../components/Header/Header'
import AppBar from './AppBar';

//PDF 
//import PdfReciboGeneral from '../components/PdfComponents/PdfReciboGeneral'


//#e0eefb
//#fafbe0

//actual #f1f8ff
const useStyles = makeStyles(() =>
    createStyles({
           
        layoutContainer: {
            background: '#fff9e0',
            minHeight: '100vh',
            
        },

        tableContainer: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            
                
        },

        tableSize: {
            width: '95%',
            height:'max-content'
        },

        searchBar: {
            height: '80px',
        },
        table: {
            borderRadius: 5,
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
            marginBottom: '20px'
        },
        header: {
            height: 'max-content'
        },
        marginTableContainer:{
            padding: '2.5% 0'
        },

        modalTable: {
            height: '100%'
        },

        button:{
            padding: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            background: "#FFF",
            borderRadius: 5,
            
            boxShadow: '0px 8px 15px rgb(214 171 0 / 34%)',
        },
       
        title: {
            background: '#FFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

        },
        customHeader: {
            background: '#FFF',
            boxShadow: '0px 8px 15px rgb(11 38 173 / 20%)',
            height: 'max-content',
            margin: '10px 0px 10px 0px',
            padding: '10px',
            borderRadius: '5px'
        }
        
    }),


    
);


const LayoutTest = ({ children, buttons=[],appBar= null, filterForm = null, filter,header=null }) => {

    const classes = useStyles()

    return (
        <Grid container className={classes.layoutContainer}>
            {header ? <Grid className={classes.header} item xs={12} >
                <Header />
            </Grid>:null}
            <Grid
                container item
                xs={12}
                className={`
                    ${classes.tableContainer} 
                    ${!(buttons.length || filterForm) ? classes.marginTableContainer : ''}
                `} >
                <Grid item className={classes.tableSize}>
                    {(buttons.length || filterForm)
                        ? < Grid container alignItems="center" xs={12} className={classes.searchBar}>
                            <Grid container item xs={12} align="center">
                                {/* BOTONES  */}
                                <Grid container item sm={4} xs={5}>
                                    {
                                        buttons.length
                                            ? buttons.map(btn => {
                                                return <Grid item style={{ marginRight: '20px' }} >
                                                    <Paper className={classes.button}>
                                                    <ButtonIcon
                                                        label={btn.label}
                                                        endIcon={btn.icon}
                                                        onClick={btn.action}
                                                    />
                                                    </Paper>
                                                </Grid>
                                                
                                            })
                                            : null
                                    }

                                </Grid>
                                {/* <Grid container alignItems="center" sm={4} item xs={4}>
                                    <Grid item>
                                        {filter}
                                    </Grid>
                                </Grid> */}
                                {/* SEARCHBAR  */}
                                <Grid item xs={12} sm={8} container alignItems="center" justifyContent="flex-end">
                                    <Grid item>
                                        {filterForm}
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        : null
                    }
                    <Grid item xs={12} className={classes.table}>
                        {appBar ?<AppBar />:null}
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default LayoutTest
