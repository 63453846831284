
import React  from "react";
import {
    Chart as ChartJS,
    ArcElement,
    PolarAreaController,
    ScatterController,
    CategoryScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  } from 'chart.js';
import { Pie } from "react-chartjs-2";

//import pattern from 'patternomaly';


ChartJS.register(
    ArcElement,
    PolarAreaController,
    ScatterController,
    CategoryScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
  );



/* const scores = [6, 5, 5, 5, 3, 4, 6, 4, 5];
const labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']; */

const options = {
    legend: {
      display: true,
      position: "right",
    },
    elements: {
      arc: {
        borderWidth: 0
      }
    }
  };

 

export default function PieChart({datos}) {

   
    const [labels, scores] = datos;
    //console.log('datos: ',datos);

    console.log('labels',labels);
    console.log('scores',scores);

    

   const data = {
      datasets: [
        {
          label: "Tipos de Convenios",
          tension: 0.3,
          data: scores,
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(23, 190, 207, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)'
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(23, 190, 207, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
        },
      ],
      labels,
    };
    

    
  

  return (
    <div className="App">
      { <Pie data={data} options={options} height='99vh' />}
    </div>
  );
}