import React from 'react'
import {Route} from "react-router-dom";
import {tokenIsExpired, decodeToken} from '../../helpers/authToken';

import history from '../../helpers/history';


//Componente para crear una ruta personalizada para autenticar
export default function AdminRoute(props) {
    return (
        <>
        {
            //console.log('decodeToken: ' ,decodeToken('admin'))
            (tokenIsExpired() || decodeToken('admin') !== 1 )?history.push('/'): <Route {...props}/>
        }
        </>
    )
}
