import React from 'react'
import {  Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';



//components
import Controls from '../Forms/Controls';

//hooks

import { useForm } from '../../hooks/useForm'



//valores iniciales del formulario
const initialFValues = {
    numero: '',
    anio: '',
    caratula: '',

}




export default function FilterForm({ setSearchText, width = '100%', closeModal = () => { }, changeBuscar = () => { } }) {

    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                width: width,
                background: "#FFF",
                height: '100%',

                '& .MuiFormControl-root': {
                    width: '100%',
                    height: '100%'
                },
            },

            fieldsContainer: {
                height: "80%",
                overflowY: 'scroll',
                overflowX: 'hidden',
                padding: '10px !important'
            },
            input: {
                color: "#6E6893",
                width: '10vh',
                marginRight: theme.spacing(2),
                flex: 1,
                '& ::placeholder': {
                    fontSize: 12
                },
                fontSize: 14
            },
            select: {
                color: "#6E6893",
                padding: 5,
                fontSize: 12,
                "& :focus": {
                    background: '#FFF'
                }
            },
            iconButton: {
                color: "#6E6893",
                padding: 5,
                border: "none"
            },
            divider: {
                height: 26,
                margin: 2,
            },

            formTitle: {
                fontWeight: 300,
            },
            formTitleContainer: {
                height: 'auto',
                margin: 10
            },

            buttons: {
                height: 'auto',
                alignItems: 'center'
            }
        }),
    );


    const classes = useStyles();


    const {
        values,
        handleChangeForm,   
    } = useForm(initialFValues);


    const onClick = () => {

        /* setSearchAnio(values.anio);
        setSearchNumero(values.numero);
        setSearchCaratula(values.caratula); */

        let valuesArray = []


        valuesArray.push(values.numero)
        valuesArray.push(values.anio)
        valuesArray.push(values.caratula)
        //console.log('search',search)

        setSearchText(valuesArray)

        changeBuscar()



        closeModal();
    }



    return (
        <Grid
            container
            spacing={1}
            className={classes.root}

        >



            <Grid item className={classes.formTitleContainer} xs={12}>
                <Typography className={classes.formTitle} variant='h5'>Filtrar convenios</Typography>
            </Grid>




            <Grid item xs={12}>
                <Controls.Input
                    name="numero"
                    label="Numero"
                    value={values.numero}
                    onChange={handleChangeForm}
                />
            </Grid>



            <Grid item xs={12}>
                <Controls.Input
                    name="anio"
                    label="Año"
                    value={values.anio}
                    onChange={handleChangeForm}
                />
            </Grid>


            <Grid item xs={12}>
                <Controls.Input
                    name="caratula"
                    label="Caratula"
                    value={values.caratula}
                    onChange={handleChangeForm}
                />
            </Grid>



            <Grid spacing={1} container className={classes.buttons} xs={12} item>
                <Grid item xs={3}>

                </Grid>
                <Grid item container justify="flex-end" direction='row' xs={9}>

                    <Controls.Button
                        onClick={onClick}
                        type="submit"
                        variant="primary"
                        text='Filtrar' />
                </Grid>
            </Grid>


        </Grid>
    )
}
