import React, { useState } from 'react'
import { useSnackbar } from "notistack";



//componentes
import LayoutTest from '../../../Layout/LayoutTest';
import FormArchivo from './FormArchivo';
import Table from '../../Table/CustomTable';
import ModalDialog from '../../Modals/ModalDialog';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';
import useModal from '../../../hooks/useModal';



//Api
import { ArchivosApi } from '../../../api/ArchivosApi';

const downloadFile = (data,enqueueSnackbar) => {
  //console.log("data downloadfile", data);
  

  let archivo = data.archivo;

  if (archivo) {

    let extension = archivo.split('.')[1]

    const body = { filename: archivo }



    const archivoDescargardo = new ArchivosApi().downloadFile

    archivoDescargardo(body)
      .then(response => {


        let buf = Buffer.from(response.data, 'base64')

        const bufferString = buf.toString('base64');

        //console.log("bufferString: ",bufferString)


        if (extension === 'pdf') {

          var file = new Blob([buf], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          var win = window.open();
          win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        } else {

          const a = document.createElement('a')
          a.href = `data:application/${extension};base64,${bufferString}`
          document.body.appendChild(a)
          a.download = `${archivo}`
          a.click()

          document.body.removeChild(a)

        }

      })
      .catch(err =>{
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      })

  } else {
    enqueueSnackbar('No hay archivo', { variant: "error" });
  }


}

const borrarArchivo = (archivoId,recargarTabla,fnCloseModal,enqueueSnackbar)=>{

   new ArchivosApi().deleteFile(archivoId)
   .then((response) => {
    enqueueSnackbar("Archivo eliminado con exito", { variant: "success" });
    recargarTabla();
    fnCloseModal();
  })
  .catch((err) => {
    enqueueSnackbar("Error al eliminar el archivo", { variant: "error" });
  });

}

export default function Archivos({ expedienteId, isFinalizado,numeroDeConvenio }) {

  //carga de datos utilizando el hook para cargar tabla
  const { dataTable, loading, recargarTabla } = useCargarTabla(new ArchivosApi().getArchivosDeExpediente, expedienteId)

  const {enqueueSnackbar} = useSnackbar();


  //estado del modal para abrir modal para crear nuevo archivo
  const [modalNuevoArchivo, setModalNuevoArchivo] = useState(false);
  const [dataEditar, setDataEditar] = useState('');
  const [archivoId,setArchivoId] = useState('');
  const modalEditArchivo = useModal();
  const modalBorrarArchivo = useModal();

  let closeModal = () => setModalNuevoArchivo(false)


  //columnas de la tabla
  const columns = [
    { title: 'Descripcion', name: 'descripcion' },
    { title: 'Fecha', name: 'created_at' },
    { title: 'Creador', name: 'creador' },
  ]

  //botones de archivos
  const buttons = [

    {
      icon: 'upload',
      action: () => setModalNuevoArchivo(!modalNuevoArchivo),
      label: 'Subir archivo'

    },
  ]


  //acciones
  const actionsEditarBorrar = [

    {
      icon: "download",
      action: (data) => downloadFile(data,enqueueSnackbar),
    },
    {
      icon: 'editar',
      action: (data) => {
        setDataEditar(data);
        modalEditArchivo.openModal()
      }
    },
    { icon: 'borrar', action: (data) =>{ 
      setArchivoId(data.id);
      setDataEditar(data);
      modalBorrarArchivo.openModal()} }
  ]

  const actions = [
    {
      icon: "download",
      action: (data) => downloadFile(data, enqueueSnackbar),
    },
  ]


  return (

    <>
      <LayoutTest

        buttons={isFinalizado ? [] : buttons}
      >

        <div style={{
          display:'flex',flexDirection:'center',justifyContent: 'center', backgroundColor: '#fff9e0',
          padding: '10px', fontWeight: 'lighter',
          border: '1px solid #dcd5c0',
          borderRadius:'5px 5px 0px 0px'
      }}>Convenio numero: {numeroDeConvenio}</div>

        {
          <ModalDialog

            labelButton="Archivos label"
            closeModal={() => setModalNuevoArchivo(!modalNuevoArchivo)}
            active={modalNuevoArchivo}
            hiddenButton={true}
          //fondo='none'

          >
            <FormArchivo  recargarTabla={recargarTabla} fnCloseModal={closeModal}></FormArchivo>

          </ModalDialog>
        }


        {
          <ModalDialog
 
            labelButton="Archivos label"
            title='Borrar Archivo'
            closeModal={() => modalBorrarArchivo.closeModal()}
            active={modalBorrarArchivo.modal}
            accionAceptar={()=>borrarArchivo(archivoId,recargarTabla,modalBorrarArchivo.closeModal,enqueueSnackbar)}
          //fondo='none'

          >
            <div>¿Desea borrar este archivo?</div>
          </ModalDialog>
            

          
        }

        {
          <ModalDialog
           
            labelButton="Archivos label"
            closeModal={() => modalEditArchivo.closeModal()}
            active={modalEditArchivo.modal}
            hiddenButton={true}
          //fondo='none'

          >
            <FormArchivo edit={true} data={dataEditar} recargarTabla={recargarTabla} fnCloseModal={modalEditArchivo.closeModal}></FormArchivo>

          </ModalDialog>
        }


        <Table
          tableData={dataTable}
          //RowMenu={()=><ActionMenu onClick={()=>{}} options={options}/>}
          collapseTable={false}
          columns={columns}
          actions={actions}
          actionsAdmin={actionsEditarBorrar}
          loading={loading}
          styleRowOptions={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', right: '3vw' }}
          pageValue={0.6}

        />

      </LayoutTest>



    </>
  )
}
