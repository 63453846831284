
import {useState} from 'react';
import { Grid } from '@material-ui/core';
import FormContainer from '../../components/Forms/FormContainer';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import RadioGroupCustom from '../Convenios/FormConvenios/RadioGroupCustom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Import components
import Controls from '../../components/Forms/Controls'




//Api
import { UserApi } from '../../api/UserApi';

//contexto
//import { AppStateContext } from '../../Contexts/appStateContextProvider';

//valores iniciales del formulario



//formulario para cargar una nueva actividad
const FormUser = ({ edit = false, dataEdit = '', recargarTabla, fnCloseModal = () => { } }) => {

  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
 

  //funciones para utilizar el formulario traidas desde el hook useForm
  const schema = yup
    .object({
      email_address: yup.string().email('Debe ser un email. Ejemplo: email@dominio.com'),
      repeatPassword: yup.string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
    })
    .required();


    const handleClickShowPassword = (setVisibility,value) => {
      setVisibility(!value);
    };
  
    
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm({
    defaultValues: {
      password:'',
      repeatPassword:'',
      nombrearea: '',
      codigoarea: '',
      contadorarea: 1,
      first_name: edit ? dataEdit.first_name : '',
      last_name: edit ? dataEdit.last_name : '',
      email_address: edit ? dataEdit.email_address : '',
      userId: edit ? dataEdit.id : '',
      is_active: edit ? (dataEdit.is_active === 1 ? 'Si':'No') : '',
      is_super_admin: edit ? (dataEdit.is_super_admin === 1 ? 'Si':'No') : '',
    },
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data, e) => {
    e.preventDefault();

    delete data.repeatPassword

    console.log('estamos aca');
    
    data.is_active === 'Si' ?  data.is_active =1: data.is_active=0;

    data.is_super_admin === 'Si' ?  data.is_super_admin =1: data.is_super_admin=0;

    if(edit){
      delete data.password;
      delete data.nombrearea;
      delete data.codigoarea;
      delete data.contadorarea;

      if(data.userId === localStorage.id)localStorage.setItem('admin',data.is_super_admin);
      await new UserApi()
      .updateUser(data)
      .then((response) => {
        enqueueSnackbar("Cargado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      });
    }else{
      await new UserApi()
      .createUser(data)
      .then((response) => {
        enqueueSnackbar("Cargado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      });
    }
    
  }

  //funcion submit del formulario 
  //const { formSubmit } = useSubmit( new UserApi().updateUser, values, recargarTabla,resetForm,fnCloseModal)

  const areas = [
    { label: 'UCU',id:1},
    { label: 'AELF',id:2 },
   ]

   const handleChangeAutocomplete = (event,onChange,value) =>{
    event.preventDefault();
    onChange(value.label)

   }

  return (
    <FormContainer
      
      width="45vw"
      LabelButtonSubmit="Guardar"
      title={edit?"Editar usuario":'Nuevo usuario'}
      buttonResetForm={false}
      autocomplete="off"
      onSubmit={handleSubmit(onSubmit)}>

     { !edit && <><Grid container item xs={12}>
        <Controller
          name="nombrearea"
          control={control}
          render={({ field: { onChange } }) =>
          <Autocomplete
          disablePortal
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, value) =>
            handleChangeAutocomplete(event, onChange, value)
          }
          options={areas}
          getOptionLabel={(option) => option.label}
          sx={{width: '100%'}}
          renderInput={(params) => <TextField {...params} label="Area"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          />}
        />

          }
        />



      </Grid>
      <Grid container item xs={12}></Grid>
      <Grid container item xs={12}>
        <Controller
          name="codigoarea"
          control={control}
          render={({ field: { onChange, value } }) =>
            <Controls.Input
              required
              name="codigoarea"
              label="Codigo del Area"
              value={value}
              onChange={onChange}
              inputProps={{
                autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },}}
            />
          }
        />

      </Grid>
      <Grid container item xs={12}></Grid>
      </>
      }

      <Grid container item xs={12}>
        <Controller
          name="first_name"
          control={control}
          render={({ field: { onChange, value } }) =>
            <Controls.Input
              required
              name="first_name"
              label="First name"
              value={value}
              onChange={onChange}
              inputProps={{
                autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },}}
            />
          }
        />

      </Grid>


      <Grid container item xs={12}></Grid>

      <Grid container item xs={12}>
        <Controller

          name="last_name"
          control={control}
          render={({ field: { onChange, value } }) => <Controls.Input
            required
            label="Last name"
            name="last_name"
            value={value}
            onChange={onChange}
            inputProps={{
              autoComplete: 'new-password',
            form: {
              autocomplete: 'off',
            },}}
          />}
        />

      </Grid>


      <Grid container item xs={12}></Grid>

      <Grid container item xs={12}>
        <Controller
          name="email_address"
          control={control}
          render={({ field: { onChange, value } }) => <Controls.Input
            required
            error={errors.email_address ? true : false}
            inputProps={{
              autoComplete: 'new-password',
            form: {
              autocomplete: 'off',
            },}}
            label="Email address"
            name="email_address"
            value={value}
            onChange={onChange}
          />}
        />

      </Grid>
      {errors.email_address && <Grid item xs={12}>{<p style={{ color: '#ff1414', marginTop: '0px' }}>{errors.email_address?.message}</p>}</Grid>}

      <Grid container item xs={12}></Grid>
{ !edit &&<>
      <Grid container item xs={12}>
   
   <Controller
     name="password"
     control={control}
     render={({ field: { onChange, value } }) =>
     <Controls.Input
     type={showPassword ? 'text': 'password'}
     fullwidth
     InputProps={{
     autoComplete: 'new-password',
      form: {
        autocomplete: 'off',
      },
       endAdornment: <InputAdornment position="end">
       <IconButton
         onClick={()=>handleClickShowPassword(setShowPassword,showPassword)}
         onMouseDown={handleMouseDownPassword}
         edge="end"
       >
         {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
       </IconButton>
     </InputAdornment>}
     }
     error={errors.repeatPassword ? true : false}
     label='Contraseña'
     name='password'
     value={value}
     onChange={onChange}
    
   />}
   />



 </Grid>

 <Grid container item xs={12}></Grid>

 <Grid container item xs={12}>
   <Controller
     name="repeatPassword"
     control={control}
     render={({ field: { onChange, value } }) => <Controls.Input
     type={showRepeatPassword ? 'text': 'password'}
     fullwidth
     InputProps={{
      autoComplete: 'new-password',
      form: {
        autocomplete: 'off',
      },
       endAdornment: <InputAdornment position="end">
       <IconButton
         onClick={()=>handleClickShowPassword(setShowRepeatPassword,showRepeatPassword)}
         onMouseDown={handleMouseDownPassword}
         edge="end"
       >
         {showRepeatPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
       </IconButton>
     </InputAdornment>}
     }
     error={errors.repeatPassword ? true : false}
     label='Repetir Contraseña'
     name='repeatPassword'
     value={value}
     onChange={onChange}
    
   />}
   />
 </Grid>

 {errors.repeatPassword && <Grid item xs={12}>{<p style={{ color: '#ff1414', marginTop: '0px' }}>{errors.repeatPassword?.message}</p>}</Grid>}</>}


      <Grid item xs={12}>
          <Controller
            name="is_super_admin"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroupCustom
                required
                name="is_super_admin"
                label="¿Es usuario administrador?"
                defaultValue={getValues('is_super_admin')!== ''?getValues('is_super_admin'):'No'}
                value={value}
                onChange={(event, value) => {
                  onChange(event, value);
                               }}
              />
            )}
          />
        </Grid>


        <Grid item xs={12}>
          <Controller
            name="is_active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroupCustom
                required
                name="is_active"
                label="¿Esta activo?"
                defaultValue={getValues('is_active')!== ''?getValues('is_active'):'No'}
                value={value}
                onChange={(event, value) => {
                  onChange(event, value);
                               }}
              />
            )}
          />
        </Grid>

     

    </FormContainer>
  )
}
export default FormUser