import React,{useState, useContext, useEffect} from 'react'



// Import components
//import FormContainer from '../../Forms/FormContainer';
import LayoutTest from '../../Layout/LayoutTest';
import Modal from '../../components/Modals/Modal'
import ModalDialog from '../../components/Modals/ModalDialog'
import ActionMenu from '../../components/Menu/ActionMenu'
import FormAsignarConvenios from './FormAsignarConvenios';
import CustomTableCheckBox from './CustomTableCheckBox';
import SearchBarMultiple from '../../components/SearchBar/SearchBarMultiple';


//contexto
import { AppStateContext } from '../../Contexts/AppStateContextProvider';


// Custom hooks
import useCargarTabla from '../../hooks/useCargarTabla';
import useModal from '../../hooks/useModal';
import useSearchFilter from '../../hooks/useSearchFilter';
import history from '../../helpers/history';



//Api
import { ExpedientesApi } from '../../api/ExpedientesApi';
import { UserApi } from '../../api/UserApi';
import DetalleConvenio from '../../components/DetalleConvenio/DetalleConvenio';

//helpers
import { decodeToken } from '../../helpers/authToken'; 


 
  

export default function AsignarConvenios() {

    const [convenio, setConvenio] = useState('');
    const [selected, setSelected] = useState([]);


    useEffect(()=>{
      const verifyAdminState = async()=>{
        await new UserApi().isSuperAdmin(decodeToken('id'))
      .then(resp=>{
        //console.log('resp: ',resp.data[0].isAdmin)
        localStorage.setItem('admin',resp.data[0].isAdmin);
        if(resp.data[0].isAdmin !== 1) history.push('/expedientes')
        
        
      })
      .catch(err=>{
        console.log('Ha ocurrido un error')
      })
      }
  
      verifyAdminState();
    },[])
    
    
    const appStateContext = useContext(AppStateContext);


  /*   let cargarPdf = () => {
    
  
     window.open(`/expedientes/${appStateContext.appState}/informe`,'_blank')
       //history.push(`/expedientes/${appStateContext.appState}/informe`)
    
    
     } */

    

    //carga de datos de convenios en gestion con id del usuario
  const { dataTable, loading,recargarTabla } = useCargarTabla(new ExpedientesApi().getAllConvenios)

  const {
    filterTable,
    setSearchText,
    showFilter,
    changeBuscar,
    filterLoading,
    setShowFilter,
  } = useSearchFilter((searchText) => new ExpedientesApi()
    .getAllConvenios(searchText), recargarTabla)


  const modalAsignarTipos = useModal();
  const modalVerConvenio = useModal();
  const modalMultipleAsignar = useModal();


     //opciones del expediente
  let options = [
    
    { title: "Ver Convenio", icon: 'view', action: () => modalVerConvenio.openModal()},

  ]


  



  //columnas de la tabla de convenios en gestion
  const columns = [
    { title: 'Numero', name: 'numero', action:()=>{} },
    { title: 'Año', name: 'anio', action:()=>{} },
    {title: 'Tipo de convenio',name: 'tipoconvenio', action:()=>{} },
    { title: 'Caratula', name: 'caratula', action:()=>{} },

  ]

  //console.log('selected array: ', selected)
  //console.log('convenio: ',convenio)


  const actions = [
     {
       icon: "editar",
       action: (data) =>{ 
        appStateContext.setAppState("");
        appStateContext.setAppState(data.id)
        setConvenio(data)
        modalAsignarTipos.openModal()
      },
     },
   ]


    return (
      <LayoutTest 

      filterForm={

        <SearchBarMultiple setSearchText={setSearchText}
          changeBuscar={() => changeBuscar()}
          closeModal={() => setShowFilter(false)} />
      }

      buttons={[]}
    appBar={true}
    header={true}

      >

{
      <ModalDialog
      hiddenButton={true}
      labelButton="Asignar Tipos a convenios"
      closeModal={() => modalAsignarTipos.closeModal()}
      active={modalAsignarTipos.modal}

    >
      <FormAsignarConvenios recargarTabla={recargarTabla} caratulaConvenio={convenio.caratula}  
      idexpediente={appStateContext.appState} fnCloseModal={()=>modalAsignarTipos.closeModal()} setSelected={setSelected}/>

    </ModalDialog>
    }

{
      <ModalDialog 
      hiddenButton={true}
      labelButton="convenio label"
      closeModal={() => modalMultipleAsignar.closeModal()}
      active={modalMultipleAsignar.modal}


    >
      <FormAsignarConvenios  recargarTabla={recargarTabla} idexpediente={appStateContext.appState} 
      setSelected={setSelected} selected={selected}  fnCloseModal={()=>modalMultipleAsignar.closeModal()}/>

    </ModalDialog>
    }


    {<Modal
        width={'55vw'}
        labelButton="Convenio label"
        closeModal={() => modalVerConvenio.closeModal()}
        active={modalVerConvenio.modal}

      >
        <DetalleConvenio convenio={convenio} fnCloseModal={() => modalVerConvenio.closeModal()} />

      </Modal>}


          <CustomTableCheckBox
          tableData={showFilter ? filterTable : dataTable} 
          RowMenu={(data) =>  <ActionMenu data={data} setConvenio={setConvenio}  options={options}  />}
          columns={columns} 
          filterTable={filterTable.length}
          actions={actions}
          filterLoading={filterLoading}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          openModal={modalMultipleAsignar.openModal}
        />
      </LayoutTest>
    )
}
