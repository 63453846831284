import AxiosIntance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Auth que sirve para loguearse en la aplicacion
export class AuthApi {

    loginUser(body){
        
        return AxiosIntance.post('auth/login',body);
    }

}
