import AxiosInstance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Archivos
export class AreaApi {

    
    getArea(areaId){
        return AxiosInstance.get(`areas/${areaId}`)
    }
    

}