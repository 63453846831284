import React, { useState,useEffect } from 'react'

//components
import LayoutTest from '../../Layout/LayoutTest'
import Table from '../../components/Table/CustomTable'
import FormUser from './FormUser';
import Modal from '../../components/Modals/Modal';
import ModalDialog from '../../components/Modals/ModalDialog';
import FormNewPassword from './FormNewPassword';


//api
import { UserApi } from '../../api/UserApi';

//hooks
import useCargarTabla from '../../hooks/useCargarTabla';
import useModal from '../../hooks/useModal';
import history from '../../helpers/history';

//helpers
import {decodeToken} from '../../helpers/authToken';

export default function User() {

  const [dataEdit, setDataEdit] = useState('')

  //obtener el contexto de la aplicacion

  useEffect(()=>{
    const verifyAdminState = async()=>{
      await new UserApi().isSuperAdmin(decodeToken('id'))
    .then(resp=>{
      localStorage.setItem('admin',resp.data[0].isAdmin);
      if(resp.data[0].isAdmin !== 1) history.push('/expedientes')
      
      
    })
    .catch(err=>{
      console.log('Ha ocurrido un error')
    })
    }

    verifyAdminState();
  },[])

  const users = new UserApi();

  const modalEditUser = useModal();
  const modalNewUser = useModal();
  const modalNewPassword = useModal();
  const { dataTable, loading, recargarTabla } = useCargarTabla(users.getAllDataUsers)


  const columns = [
    { title: 'Username', name: 'username', action: () => alert('hola username') },
    { title: 'First Name', name: 'first_name', action: () => alert('firstname') },
    { title: 'Last Name', name: 'last_name', action: () => { } },
    { title: 'Created at', name: 'created_at', action: () => alert('hola created at') },
    { title: 'Nombre', name: 'nombre', action: () => alert('hola nombre') },
    { title: 'Last login', name: 'last_login', action: () => alert('hola last login') },

  ]



  const actions = [
    {
      icon: "editar",
      action: (data) => {
        console.log('data: ', data)

        setDataEdit(data);

        modalEditUser.openModal()
      },
      tooltipTitle: 'Editar usuario'
    },
    {
      icon: 'password',
      action: (data) => {
        setDataEdit(data);
        modalNewPassword.openModal()
      },
      tooltipTitle: 'Cambiar contraseña'
    },
    {
      icon: 'borrar',
      action: (data) => { alert(JSON.stringify(data)) },
      tooltipTitle: 'Eliminar usuario',

    }
  ]

  const buttons = [

    {
      icon: 'nuevo',
      action: () => modalNewUser.openModal(),
      label: 'Nuevo Usuario'

    }

  ]

  return (
    <LayoutTest
      header={true}
      appBar={false}
      buttons={buttons}
    >

      {

        <Modal
          width={'45vw'}
          labelButton="Editar label"
          closeModal={() => modalEditUser.closeModal()}
          active={modalEditUser.modal}

        >
          <FormUser recargarTabla={recargarTabla} dataEdit={dataEdit} edit={true} fnCloseModal={modalEditUser.closeModal} />

        </Modal>



      }

      {
        <ModalDialog


          closeModal={() => modalNewPassword.closeModal()}
          active={modalNewPassword.modal}
          hiddenButton={true}
          accionAceptar={() => { }}
          fondo='none'
        >

          <FormNewPassword data={dataEdit} recargarTabla={recargarTabla} fnCloseModal={modalNewPassword.closeModal}></FormNewPassword>

        </ModalDialog>
      }


      {<Modal
        width={'45vw'}
        labelButton="Nuevo usuario label"
        closeModal={() => modalNewUser.closeModal()}
        active={modalNewUser.modal}

      >
        <FormUser recargarTabla={recargarTabla} fnCloseModal={modalNewUser.closeModal} />

      </Modal>}
      <Table
        tableData={dataTable}
        //RowMenu={(data) => <ActionMenu onClick={() => { handleClickRowMenu(data, appStateContext) }} options={options} />}
        collapseTable={false}
        columns={columns}
        actions={actions}
        loading={loading}
        pageValue={0.6}
      >

      </Table>

    </LayoutTest>
  )
}
