import axios from "axios"
require('dotenv').config()

//import { getStoredAuthToken } from "../utils/authToken"

const backendURL = process.env.REACT_APP_BACKEND_URL

//console.log('backendurl: ',backendURL)
//instancia de axios configurada con la url de la api
const AxiosInstance = axios.create(
    {
        baseURL:  backendURL,
    })
AxiosInstance.interceptors.request.use(
    (config) => {
        const access_token  = localStorage.access_token || ''
        if (access_token) {
            config.headers.Authorization = `Bearer ${access_token}`
        }

        return config

    },
    (error) => {
        return Promise.reject(error);
    }
)


export default AxiosInstance