import AxiosInstance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Expedientes
export class ExpedientesApi {

    postExpediente(body){
        return AxiosInstance.post('expedientes',body)
    }


    finalizarExpediente(idexpediente){
        return AxiosInstance.put(`expedientes/finalizarexpediente/${idexpediente}`);
    }

    aprobarExpediente(idexpediente,numero){
        const body={
            "numeroDeConvenio": numero,
        };
        return AxiosInstance.put(`expedientes/updateaprobado/${idexpediente}`,body);
    }

    async esCreador(idexpediente,idusuario){
        return await AxiosInstance.get(`expedientes/creador/convenio/${idexpediente}/usuario/${idusuario}`)
    }

    quitarExpediente(idexpediente){
        const body ={"dictamen": false};
        return AxiosInstance.put(`expedientes/dictamen/${idexpediente}`,body);
    }

    mandarADictamen(idexpediente,dictamen){

        const body ={"dictamen": dictamen};
        return AxiosInstance.put(`expedientes/dictamen/${idexpediente}`,body);
    }


    agregarParticipante(body){
        return AxiosInstance.post(`expedientes/agregarusuarioaexpediente`,body);
    }

    getExpedientePorId(expedienteId){

        return AxiosInstance.get(`expedientes/expediente/${expedienteId}`)
        
    }
    

    getAllConvenios(searchText=['','','']){
        return AxiosInstance.get(`expedientes/all?numero=${searchText[0]?searchText[0]:''}&anio=${searchText[1]?searchText[1]:''}&caratula=${searchText[2]?searchText[2]:''}`)
    }

    getTiposDeConvenios(){
        return AxiosInstance.get(`expedientes/tiposdeexpediente`);
    }

    getConveniosMarco(query=''){
        return AxiosInstance.get(`expedientes/conveniosmarco${query}`)
    }

    updateExpediente(body){
        console.log('body: ', body)
        let expedienteId=body.expedienteId;
        delete  body.expedienteId
        console.log('expedienteid: ', expedienteId);

        return AxiosInstance.put(`expedientes/expediente/${expedienteId}`,body)
    }

    updateTipoExpediente(idObject){
        return AxiosInstance.put(`expedientes/updatetipoexpediente`,idObject)
    }

    getDocumentLink(expedienteId){
        return AxiosInstance.get(`expedientes/documentlink/${expedienteId}`)
    }

    getExpedientesFirmados(searchText=['','','',0]){
        return AxiosInstance.get(`expedientes/expedientesfirmados?numero=${searchText[0]?searchText[0]:''}&anio=${searchText[1]?searchText[1]:''}&caratula=${searchText[2]?searchText[2]:''}&vencidos=${searchText[3]?searchText[3]:''}`);
    }


    getExpedientesFinalizados(searchText=['','','']){

        
        return AxiosInstance.get(`expedientes/expedientesfinalizados?numero=${searchText[0]?searchText[0]:''}&anio=${searchText[1]?searchText[1]:''}&caratula=${searchText[2]?searchText[2]:''}`);
    }

    getExpedientesEnGestion(userId,searchText=['','','']){
        console.log("searchText: ", searchText);
        return AxiosInstance.get(`expedientes/${userId}?numero=${searchText[0]}&anio=${searchText[1]}&caratula=${searchText[2]}`)
    }

    getExpedientesEnDictamen(searchText=['','','']){
        console.log("searchText: ", searchText);
        if(searchText === "")searchText=['','',''];
        return AxiosInstance.get(`expedientes/dictamen?numero=${searchText[0]}&anio=${searchText[1]}&caratula=${searchText[2]}`)
    }


    getConveniosPorTipo(){
        return AxiosInstance.get(`expedientes/conveniosportipo`);    }

    getTipoDeConvenioPorAnio(){
        return AxiosInstance.get('expedientes/tiposdeconvenioporanio');
    }

}

