import React, { useState, createContext } from "react";

export const AppStateContext = createContext();

const AppStateContextProvider = props => {
  const [appState, setAppState] = useState({
    ExpedienteId: "",
    ubicacion: "asdsafsa",
    name: "asfasas2"
  });

  return <AppStateContext.Provider value={{ appState, setAppState }}>{props.children}</AppStateContext.Provider>;
};

export default AppStateContextProvider;
