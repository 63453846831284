import history from "./history";

//funcion que obtiene el token en localStorage
export const getStoredAuthToken = () => localStorage.getItem('access_token');

//funcion que guarda el token en localStorage
export const storeAuthToken = token => localStorage.setItem('access_token', token);

//funcion que elimina el token en localStorage y redirige al login
export const removeStoredAuthToken = () => {
  localStorage.clear()
  history.push('/');
};

//funcion que pregunta si el token esta expirado
export const tokenIsExpired = () => {
  const token = getStoredAuthToken()
  if (!token) return true
  const { exp } = JSON.parse(atob(token.split('.')[1]))
  //console.log('exp: ',exp);
  return !(exp > new Date().getTime()/ 1000)
}

//funcion que descifra el token en localStorage
export const decodeToken = (prop) => {
  const token = getStoredAuthToken()
  if(!token) return undefined

  var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    const tokenDecode = JSON.parse(jsonPayload);

  //const tokenDecode = JSON.parse(atob(token.split('.')[1]))

  return tokenDecode[prop] || ''

}