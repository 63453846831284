import { useEffect, useState } from "react";
import { useSnackbar } from 'notistack';


//hook para cargar tabla devuelve la tabla, el estado loading y una funcion para recargar tabla
const useCargarTabla = (promise,arg='') => {

    const { enqueueSnackbar } = useSnackbar(); 
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [recargar, setRecargar] = useState(false)
    const loadingTime = ()=>setTimeout(()=>setLoading(false),1);

    const cargarDatos = async () => {

        setLoading(true)
        //console.log('arg',arg)
        
        promise(arg)
            .then(resp => {
                //console.log("respuesta",resp)
                setData(resp.data)
                setLoading(false);
                
            })
            .catch(err => {
                setLoading(false);
                setData(['error al cargar datos'])
                enqueueSnackbar('No se pudo obtener datos', { variant: 'error' })
                console.log("ERROR SET DATA HOOK CARGAR DATOS", err)})

    }
    
    useEffect(() => {
         cargarDatos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const recargarTabla = () => {
        console.log("ENTRE !! ")
        setData([])
        setRecargar(!recargar)
        cargarDatos()
    }

    return {
        dataTable: data,
        loading,
        recargarTabla,

    }
}

export default useCargarTabla