import { useState,useEffect } from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import FormContainer from "../../../components/Forms/FormContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import useMediaQuery from '@mui/material/useMediaQuery';


//refactorizar utils
//utils
import {decodeToken} from '../../../helpers/authToken';


// Import components
import Controls from "../../../components/Forms/Controls";






//Api
import {UserApi} from '../../../api/UserApi';
import {ExpedientesApi} from '../../../api/ExpedientesApi';

const handleOnChange = (e,data,setData)=> {
  e.preventDefault();
  console.log('selectedUser: ',data);
  setData('email', data.email_address);
  setData('userID',data.id);

}



export default function FormTrayectoria({ expedienteID,fnCloseModal=()=>{}, recargarTabla=()=>{} }) {

    const [users, setUsers] = useState([]);
    

  const matchFullHD = useMediaQuery('(min-width:2000px)');

  const { enqueueSnackbar } = useSnackbar();

  useEffect( () => {

    async function fetchData(){

    
        await new UserApi().getAllUsers()
        .then(resp=>{
            setUsers(resp.data);
        })
    }

    fetchData();

   

  },[])

  //checkbox icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

console.log('users: ',users)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      expedienteID: expedienteID,
      userID: null,
      email: ''
    }
  });

  const onSubmit = async (data) => {

    await new ExpedientesApi()
    .agregarParticipante(data)
    .then((response) => {
      enqueueSnackbar("Participante agregado", { variant: "success" });
      recargarTabla();
      fnCloseModal();
    })
    .catch((err) => {
      enqueueSnackbar("No se ha podido agregar participante", { variant: "error" });
    });
    
    //alert(JSON.stringify(data))

  }


  return (
    <FormContainer
  
      LabelButtonSubmit="Agregar"
      buttonResetForm={false}
      //resetForm={resetForm}
      title={"Agregar participante"}
      onSubmit={handleSubmit(onSubmit)}
    >


        <Grid item xs={12}>
        <Autocomplete
            fullWidth={true}
          
            onChange={(event, values) => {
              
              handleOnChange(event,values,setValue);
            }}
            id="id"
            size={matchFullHD?'medium':'small'}
            options={users}
            groupBy={(option) => option.areas}
            disableCloseOnSelect
            getOptionLabel={(option) => option.nombre}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox

                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}

                />
                {option.nombre}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size={matchFullHD?'medium':'small'}
                label="Usuarios"
                placeholder="Usuarios"
              />
            )}
          /> 
        </Grid>


    </FormContainer>
  );
}
