import { Fragment } from "react";
import { Router, Switch,Route } from "react-router-dom";
import history from "./helpers/history";
import { useMediaQuery } from "@material-ui/core";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { PDFViewer } from '@react-pdf/renderer'


//Components
import AuthRoute from "./components/AuthRoute/AuthRoute";
import AdminRoute from "./components/AuthRoute/AdminRoute";
import LoginRoute from "./components/AuthRoute/LoginRoute";
import GeneratePdf from "./components/GeneratePdf/GeneratePdf";
import Estadisticas from "./pages/Estadisticas/Estadisticas";

//contexts
import AppStateContextProvider from "./Contexts/AppStateContextProvider";

//Page

import ConveniosFinalizados from "./pages/ConveniosFinalizados/ConveniosFinalizados";
import Convenios from "./pages/Convenios/Convenios";
import Login from "./pages/Login/Login";
import User from "./pages/User/User";
import AsignarConvenios from "./pages/AsignarConvenios/AsignarConvenios";
import NotFound from "./pages/NotFound/NotFound";
import ConveniosDictamen from "./pages/ConveniosDictamen/ConveniosDictamen";





function App() {

  

  const minsm = useMediaQuery('(min-width:600px)')
  const minmd = useMediaQuery('(min-width:960px)')
  const minlg = useMediaQuery('(min-width:1280px)')
  const minxl = useMediaQuery('(min-width:1920px)')

  const sm = (minsm && !minmd)
  const md = (minmd && !minlg)
  const lg = (minlg && !minxl)
  //const xl = minxl

  const outerTheme = createTheme({
    spacing: sm ? 3.25 : md ? 3.5 : lg ? 3.5 : 8,
    typography: {
      fontSize: sm ? 12 : md ? 12 : lg ? 14 : 15,
    },
    
  })


  return (
    <ThemeProvider theme={outerTheme}>
      <AppStateContextProvider>
        <Router history={history}>


          <Switch>



            <LoginRoute exact path="/" component={Login} />


            <AuthRoute exact path="/expedientesfinalizados" component={ConveniosFinalizados} />


            <AuthRoute exact path="/expedientes" component={Convenios} />

            <AdminRoute exact path="/asignartipos" component={AsignarConvenios} />

            <AuthRoute exact path="/dictamen" component={ConveniosDictamen} />

            <AuthRoute exact path="/expedientes/:id/informe"  >

              <Fragment>
                <PDFViewer style={{display: 'inline-block'}} width="100%" height="1000px"  >
                  <GeneratePdf datosPdf={localStorage.datosPdf?JSON.parse(localStorage.datosPdf):[]}
                    actividadesExpediente={localStorage.actividadesExpediente?JSON.parse(localStorage.actividadesExpediente):[]} />

                </PDFViewer>
              </Fragment>


            </AuthRoute>


            <AdminRoute exact path="/user" component={User} />

            <Route exact path='/estadisticas' component={Estadisticas}/>

            <Route component={NotFound} />

            



          </Switch>
        </Router>
      </AppStateContextProvider>
    </ThemeProvider>
  );
}

export default App;
