import React,{ useState} from 'react'




//componentes
import LayoutTest from '../../../Layout/LayoutTest';
import FormActividad from '../Actividades/FormActividad';
import Table from '../../Table/CustomTable';
import Modal from '../../Modals/Modal';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';


//Api
import {ActividadesApi} from '../../../api/ActividadesApi';


//Modal de Actividades de expediente recibe el id de expediente y devuelve el componente renderizado
export default function Actividades({expedienteId}) {
    
  //carga de actividades utilizando el hook useCargarTabal()
    const {dataTable,loading,recargarTabla}= useCargarTabla( new ActividadesApi().getActividadesDeExpediente,expedienteId)

    //estado de modal para mostrar el form de nueva actividad
    const [modalNuevaActividad, setModalNuevaActividad] = useState(false)

    const closeModal = () =>setModalNuevaActividad(false)


    

    //columnas de la tabla Actividades
    const columns = [
        { title: 'Fecha', name:'created_at'},
        { title: 'Descripcion', name:'descripcion'},
        { title: 'Creador', name:'creador'},
      ]

      //botones de Actividades
    const buttons = [
        {
          icon: 'noteadd',
          action: () => setModalNuevaActividad(!modalNuevaActividad),
          label: 'Nuevo'
    
        },
        
      ]

    return (
        <LayoutTest
    
      buttons={buttons}
    >
      {
        <Modal
        width={'45vw'}
        labelButton="Actividades label"
        closeModal={() => setModalNuevaActividad(!modalNuevaActividad)}
        active={modalNuevaActividad}
        fondo='none'

      >
        <FormActividad recargarTabla={recargarTabla} fnCloseModal={closeModal}></FormActividad>
        
      </Modal>
      }
      <Table
        tableData={dataTable}
        //RowMenu={()=><ActionMenu onClick={()=>{}} options={options}/>}
        collapseTable={false}
        columns={columns}
        actions={[]}
        loading={loading}
      />
    </LayoutTest>
    )
}
