import { makeStyles, createStyles, Grid, Paper, Typography } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react'
import LogoUcu from './LogoUCU.png';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '90%',
      background: "#FFF",
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    paperContainer: {
      height: '95%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    header: {

      height: '15vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f1db14',
      borderRadius: '5px',
    },
    imgContainer: {
      width:'50%',
    },
    img: {
      marginLeft:'20px',
      height: 'auto',
      width: '15vw',

    },
    title: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    mainContainer: {

      width: '95%',
      height: '100%',
      display:'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    encabezado: {
      padding: 0,
      height: '6vh',
      margin: '10px 0px 10px 0px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderRadius: '5px',
      
    },
 
    itemEncabezado:{
      marginLeft:'5px'
    },

    caratula: {

      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '6vh',
      borderRadius: '5px',
  

    },
    objeto: {
      
      minHeight: '45vh',
      height: 'max-content',
      width: '100%',
      display: 'flex',
      borderRadius: '10px',
      
    },
    textObjeto:{
      margin:'10px',
    }
  })
)

export default function DetalleConvenio({ convenio }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 100);
  }, [])

  const classes = useStyles();

  console.log('convenio detalle: ', convenio)
  return (

    <div className={classes.root}>

      <div className={classes.paperContainer} elevation={5}>
        <div item className={classes.header}>
          <div className={classes.imgContainer}>
            <img className={classes.img} src={LogoUcu} alt="logo ucu" />
          </div>
          <div item className={classes.title} ><Typography>INFORME DE CONVENIO</Typography></div>
        </div>



        {loading ? <CircularProgress className={classes.mainContainer} /> :
          <>
            <div className={classes.mainContainer}>
              <Paper className={classes.encabezado} elevation={5}>
                <div  className={classes.itemEncabezado}><Typography>Numero: {convenio.numero}</Typography></div>
                <div className={classes.itemEncabezado}><Typography>Año: {convenio.anio}</Typography></div>
                <div className={classes.itemEncabezado}><Typography>Vigencia de: {convenio.vigenciade}</Typography></div>
                <div className={classes.itemEncabezado}><Typography>A: {convenio.a && convenio.a !== '00/00/0000' ? convenio.a : 'No vence'}</Typography></div>
              </Paper>




              <Paper elevation={5} className={classes.caratula} >

                <Typography  >  Caratula: {convenio.caratula}</Typography>

              </Paper>

              <Paper elevation={5} className={classes.objeto} >



                <Typography className={classes.textObjeto} >
                  OBJETO: {convenio.objeto}
                </Typography>

              </Paper>

            </div>
          </>
        }

      </div>
    </div>

  )
}
