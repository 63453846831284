
import { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { Grid, Typography } from "@material-ui/core";
//import { useDispatch, } from 'react-redux'


import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import ButtonIcon from "../Buttons/ButtonIcon";
import Icons from "../Icons";

//actions
//import { openModal } from '../../Redux/actions/modales/ActionCreatorModals';
//import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

//custom hooks
import { usePagination } from "../../hooks/usePagination";

import {decodeToken} from '../../helpers/authToken';


//component
import RowHeader from "./RowHeader";
import { Row } from "../Row";
import TablePaginationActions from "./TablePaginationActions";
import { Cell, CellAction } from "../Cell";


// estilos css-in-js
const useStyles = makeStyles(() =>
  createStyles({
    table: {
      minWidth: 500,
      width: "100% !important",
      overflowX: "scroll",
      overflowY: "scroll",
      textOverflow: 'elipsis',
      height: 'max-content',
    },
    paginationTable: {
      color: "#6E6893",
      height: 35,
    },
    footer: {
      height: 35,
    },
    root: {
      height: 'max-content',
      "& > *": {
        textOverflow: 'elipsis',
        borderBottom: "1px solid rgb(241 241 241)",
      },
    },
    cellHead: {
      '&:hover': {
        cursor: 'pointer',
      }
    },
    loading: {
      width: "100%",
    },

    collapseTable: {
      overflow: "hidden",
      background: "#FFF",
      textOverflow: 'elipsis'
    },

    borderOpen: {
      textOverflow: 'elipsis',
      borderLeft: "solid 5px #8cbaff ",
      background: "#d9e8ff",
    },

    borderClose: {
      borderLeft: "solid 5px transparent ",
    },
    noResult: {
      marginLeft:'40%'
    }
  })
);


const CustomTable = (props) => {
  const classes = useStyles();
  //const arrow = useArrow();

  const {

    tableData,
    columns,
    actions = null,
    actionsAdmin = null,
    RowMenu = null,
    pagination = true,
    filterTable = false,
    loading = false,
    filterLoading = false,
  } = props;





  //console.log('loading: ',loading);
  //console.log('filterLoading: ',filterLoading);
  

  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));






  // usePagination devuelve:
  // page: devuelve la pagina actual,
  // rowperPage: devuelve las filas por pagina,
  // handleChangePage: fun. para cambiar pagina,
  const rowsPerPage = matchesXL ? 12 : matchesLG ? 10 : matchesMD ? 8 : matchesSM ? 6 : 5

  const { page, handleChangePage } = usePagination()

  useEffect((event)=>{
    handleChangePage(event,0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filterLoading,loading])
  




  // emptyRows se usa para rellenar el espacio faltante
  // de la tabla para mantener el tamaño correspondiente
  // a filas por pagina

  //if(!filterTable) handleChangePage('',0);
  
  

  /* console.log('page: ', page);

  console.log('rowsPerPage: ',rowsPerPage);

  console.log('tableData.length: ',tableData.length);

  console.log('filterTable: ', filterTable); */

  
 

  //filterTable && filterTable < rowsPerPage ? console.log('page: ',0) : console.log('page: ',page)


  const emptyRows =
  rowsPerPage - Math.min(rowsPerPage, tableData.length === 0 ? 0 : (tableData.length -  page * rowsPerPage));



  //console.log('emptyRows: ',emptyRows);

   // funcion que corta el array dependiendo las filas por paginas
  // y selecciona la lista correspondiente

  const ActualPage = (listData) => {
    return (listData).slice((filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage,
      (filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage)
  }

  /* console.log('cuenta', (filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage,
  (filterTable && filterTable < rowsPerPage ? 0 : page) * rowsPerPage + rowsPerPage); */

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }



  let fechaActual = formatDate(new Date());
  return (
    <>
     
      <Table className={classes.table} aria-label="tabla">
        <RowHeader columns={columns}>
          {columns.map((col, i) => (
            <Cell variant="head" key={i} >
              {col.title}
            </Cell>
          ))}
        </RowHeader>
        <TableBody>
          {loading || filterLoading ? (
            <>

              <TableCell className={classes.loading} colSpan={15}>
                <LinearProgress color='warning'/>
              </TableCell>

              <TableRow style={{ height: 35 * (rowsPerPage ? rowsPerPage : emptyRows) }}>
                <TableCell colSpan={15} />
              </TableRow>
            </>

          ) : null
          }

          {tableData.length && !loading && !filterLoading
            ? (rowsPerPage > 0 ? ActualPage(tableData) : tableData).map(
              (data, i) => {
                return (
                  <>
                    <Row
                      className={`${classes.root} ${classes.borderClose
                        }`}
                    >

                      {columns.map((key, j) => {
                      
                        return key.title === " " ? null : (
                          <Cell
                            allRowData={data}
                            columna={key.title}
                            openModal={
                              () =>
                                key.openModal &&
                                null /* dispatch(openModal()) */
                            }
                            boolean={key.boolean}
                            isArray={key.isArray}
                            width={key.width}
                            action={key.action}
                            align={key.align}
                          >
                            
                            {key.type === "boolean" ? (
                              <Icons
                                type={
                                  key.name
                                    ? !!data[key.name] ||
                                    !!data[key.name.toLowerCase()]
                                    : !!data[key.title.toLowerCase()]
                                }
                              />
                            ) : key.name ? (
                              data[key.name] || data[key.name.toLowerCase()]
                            ) : (
                              data[key.title.toLowerCase()]
                            )}
                          </Cell>
                        );
                      })}
                      <CellAction align='right'>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                           
                                            {RowMenu && RowMenu(data)}
                                            {(actionsAdmin && (data.created_by === decodeToken('id') &&
                            (fechaActual === data.created_at)) ? actionsAdmin : actions).map((accion, index) =>
                                                {if (
                                                  (data.hasOwnProperty("archivo") &&
                                                    data.archivo === null
                                                    && accion.icon === 'download')
                  
                                                ) {
                                                  return <div style={{ marginRight: '10px' }}>No hay archivo</div>;
                                                } else {
                                                  return (
                                                    <div style={{ marginRight: '10px' }}>
                                                      <ButtonIcon
                                                        tooltipTitle={accion.tooltipTitle}
                                                        key={index}
                                                        onClick={() => accion.action(data)}
                                                        centerIcon={accion.icon}
                                                      />
                                                    </div>
                                                  );
                                                }}

                                            )
                                            }
                                        </div>
                                    </CellAction>
                    </Row>
                   
                  </>
                );
              }
            )
            : null}
          {/*---------------------------------------------------------*/}


          {
          (tableData.length === 0 && !loading && !filterLoading) ? (
            
            <TableRow style={{ height: 57 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
              {console.log('no hay nada aca')}
              <TableCell colSpan={15}><Grid className={classes.noResult}><Typography>No hay resultados</Typography></Grid> </TableCell>
            </TableRow>)
            :
          
          (emptyRows > 0 && !loading && !filterLoading) ? (
                        <TableRow style={{ height: 50 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
                            <TableCell colSpan={15} />
                        </TableRow>
                    ) : null
                    }
         
          {/* {(emptyRows > 0 && tableData.length === 0 && !loading) ? (
            <TableRow style={{ height: 57 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
              <TableCell colSpan={15}><Grid className={classes.noResult}><Typography>No hay resultados</Typography></Grid> </TableCell>
            </TableRow>
          ) :
          (emptyRows > 0 || (emptyRows > 0 && filterTable)) ? (
            <TableRow style={{ height: 57 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
                <TableCell colSpan={15} />
            </TableRow>
        ) : null
          } */}
        </TableBody>
        <TableFooter className={classes.footer}>
          {pagination ? (
            <TableRow>
              <TablePagination
                className={classes.paginationTable}
                align="right"
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[]}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          ) : null}
        </TableFooter>
      </Table>
    </>
  );
};

export default CustomTable;
