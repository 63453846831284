import { useState } from 'react'



//contexto de la aplicacion
//import { AppStateContext } from '../../Contexts/appStateContextProvider';



//components
import Table from '../../components/Table/CustomTable'
import Modal from '../../components/Modals/Modal'
import Trayectorias from '../../components/ModaTable/Trayectorias/Trayectorias'
import Actividades from '../../components/ModaTable/Actividades/Actividades'
import Archivos from '../../components/ModaTable/Archivos/Archivos'
import LayoutTest from '../../Layout/LayoutTest'
import ActionMenu from '../../components/Menu/ActionMenu'
import FilterForm from '../../components/Forms/FilterForm';
import ModalDialog from '../../components/Modals/ModalDialog';
import SearchBarMultiple from '../../components/SearchBar/SearchBarMultiple';
import DetalleConvenio from '../../components/DetalleConvenio/DetalleConvenio';
import LinkContainer from '../../components/LinkContainer/LinkContainer';

//hook
import useCargarTabla from '../../hooks/useCargarTabla'
import useSearchFilter from '../../hooks/useSearchFilter';
import useModal from '../../hooks/useModal';


//helpers
//import history from '../../helpers/history';

//api
import { ExpedientesApi } from '../../api/ExpedientesApi'
//import { ActividadesApi } from '../../api/ActividadesApi';


const cargarPdf = (data) => {


  //console.log('data convenios finalizados: ',data)

  localStorage.setItem('datosPdf', JSON.stringify(data));

 /*  const actividadesApi = new ActividadesApi()

  actividadesApi.getActividadesDeExpediente(data.id)
    .then(response => {
      //console.log(response)

      localStorage.setItem('actividadesExpediente', JSON.stringify(response.data))
    }) */

  window.open(`/expedientes/${data.id}/informe`, '_blank')
  //history.push(`/expedientes/${appStateContext.appState}/informe`)


}


//abrir link de google docs del expediente
const openLinkInANewTab = async (data,setLink) => {

  console.log('openLink data: ', data)


  await new ExpedientesApi().getDocumentLink(data.id)
  .then(resp => {

    console.log(resp.data[0]);
    /*  let a = document.createElement('a');
     let link = resp.data[0].documentlink;
     a.target = '_blank'
     a.href = link;
     a.click(); */
    setLink(resp.data[0].documentlink)
  })
 /*  let a = document.createElement('a');
  let link = data.documentlink;

  a.target = '_blank'
  a.href = link;
  a.click(); */

}

const getConvenio = async (convenioId, setData) => {

  new ExpedientesApi().getExpedientePorId(convenioId)
    .then(resp => {
      console.log('s: ', resp)
      setData(resp.data[0])
    })

}




export default function ConveniosFinalizados() {

  const [expedienteId, setExpedienteId] = useState('');
  const [convenio, setConvenio] = useState('');
  const [linkGoogleDocs, setLinkGoogleDocs] = useState('');

  //modales de las opciones de convenios finalizados
  const modalTrayectorias = useModal();
  const modalActividades = useModal();
  const modalArchivos = useModal();
  const modalFilter = useModal();
  const modalDetalleConvenio = useModal();
  const modalGoogleDocsLink = useModal();

  //obtener el contexto de la aplicacion
  //const appStateContext = useContext(AppStateContext);

  //carga de datos de convenios en gestion con id del usuario
  const { dataTable, loading } = useCargarTabla(new ExpedientesApi().getExpedientesFinalizados)



  const {
    filterTable,
    showFilter,
    setSearchText,
    changeBuscar,
    filterLoading,
    setShowFilter, } = useSearchFilter((searchText) => new ExpedientesApi()
      .getExpedientesFinalizados(searchText))

  //console.log('searchText: ')



  //opciones del expediente
  const options = [
    {
      title: "Link Google Docs", icon: 'link', action: (data) => {
        modalGoogleDocsLink.openModal();
        openLinkInANewTab(data, setLinkGoogleDocs)
      }
    },
    {
      title: "Ver Detalle Convenio", icon: 'view', action: (data) => {
        modalDetalleConvenio.openModal();
        getConvenio(data.id, setConvenio);
      }
    },
    {
      title: "Ver Convenio marco", icon: 'view', action: (data) => {
        modalDetalleConvenio.openModal();
        console.log('data: ',data)
        getConvenio(data.conveniomarcoid, setConvenio);
      }
    },
/*     { title: "Actividades", icon: 'activity', action: () => modalActividades.openModal() }, */
    {title: "Participantes", icon: 'users', action: () => modalTrayectorias.openModal() },
    { title: "Archivos", icon: 'file', action: (data) => {
      setConvenio(data)
      modalArchivos.openModal()} 
    },

    { title: "Ver PDF", icon: 'description', action: (data) => cargarPdf(data) },

  ]




  //columna del expediente
  const columns = [
    { title: 'Numero', name: 'numero'},
    { title: 'Año', name: 'anio' },
    { title: 'Tipo de convenio', name: 'tipoconvenio' },
    { title: 'Caratula', name: 'caratula' },
    { title: 'Vencimiento', name: 'a'},



  ]


  //botones de la pagina
  const buttons = [


  ]


  return <LayoutTest

    filterForm={

      <SearchBarMultiple setSearchText={setSearchText}
        changeBuscar={() => changeBuscar()}
        closeModal={() => setShowFilter(false)} />
    }

    buttons={buttons}
    appBar={true}
    header={true}
  >
    {
      modalGoogleDocsLink.modal &&
      <ModalDialog

        title='Link del Convenio en Google Docs'
        closeModal={() => modalGoogleDocsLink.closeModal()}
        active={modalGoogleDocsLink.modal}
        hiddenButton={true}
        accionAceptar={() => { }}
      >
        <LinkContainer linkGoogleDocs={linkGoogleDocs} />

      </ModalDialog>
    }

    {
      modalDetalleConvenio.modal &&
      <Modal
        width={'45vw'}
        closeModal={() => modalDetalleConvenio.closeModal()}
        active={modalDetalleConvenio.modal}
      >
        <DetalleConvenio convenio={convenio} />
      </Modal>
    }

    {
      modalFilter.modal &&
      <ModalDialog
      width={'500px'}
      height={'350px'}

      closeModal={() => modalFilter.closeModal()}
      active={modalFilter.modal}
      hiddenButton={true}
      accionAceptar={() => { }}
      fondo='none'

    >
      <FilterForm
        setSearchText={setSearchText}
        changeBuscar={() => changeBuscar()}
        closeModal={() => modalFilter.closeModal()}

      //search={()=>setBuscar(true)}

      />


    </ModalDialog>}
    {
      modalTrayectorias.modal &&
      <Modal
        width={'45vw'}
        labelButton="Trayectorias label"
        closeModal={() => modalTrayectorias.closeModal()}
        active={modalTrayectorias.modal}

      >
        <Trayectorias expedienteId={expedienteId} isFinalizado={true}></Trayectorias>

      </Modal>
    }

    {
      modalArchivos.modal &&
      <Modal
        width={'65vw'}
        labelButton="Archivos label"
        closeModal={() => modalArchivos.closeModal()}
        active={modalArchivos.modal}

      >
        <Archivos expedienteId={expedienteId}  isFinalizado={true} numeroDeConvenio={convenio.numero}></Archivos>

      </Modal>
    }

    {
      modalActividades.modal &&
      <Modal
        width={'45vw'}
        labelButton="Actividades label"
        closeModal={() => modalActividades.closeModal()}
        active={modalActividades.modal}

      >
        <Actividades expedienteId={expedienteId} />

      </Modal>
    }
    {
      <Table
        tableData={showFilter ? filterTable : dataTable}
        RowMenu={(data) => <ActionMenu data={data} setExpedienteId={setExpedienteId} options={options} />}
        collapseTable={false}
        columns={columns}
        actions={[]}
        filterTable={filterTable.length}
        filterLoading={filterLoading}
        loading={loading}




      />
    }
  </LayoutTest>
}

