


import { KeyboardDatePicker } from '@material-ui/pickers';


import moment from 'moment'



const DateField = (props) => {

    const { name, title, value =null, onChange, onBlur,minDate=new Date(),required=false, onKeyPress='' } = props

    //console.log("fechas  ", value, " ", moment(value).format("DD/MM/YYYY"))
    let today = new Date();
    let datePlaceholder = today.getDate() + "/"+ today.getMonth()+1+ "/" +today.getFullYear();

    return (
      /*   <KeyboardDatePicker
        
            inputVariant="outlined"
            margin="dense"
            required={required}
            autoOk
            onBlur={onBlur}
            label={title}
            openTo="date"
            inputValue={value || null}
            name={name}
            disableToolbar={false}
            onKeyPress={!onKeyPress ? (e) => { e.key === 'Enter' && e.preventDefault(); }: onKeyPress}
            onChange={(date, value) => {
                onChange({ target: { value: date && date.isValid && moment(date).format('DD/MM/YYYY'), name } })
                
            }}
            minDate={minDate}
            minDateMessage='estas seleccionando una fecha invalida'
            value={value || null}
            format={'DD/MM/YYYY'}
            placeholder={datePlaceholder}
            style={{ margin: 0 }}
        /> */


        <KeyboardDatePicker
            required={required}
            inputVariant="outlined"
            margin="dense"
            autoOk
            onBlur={onBlur}
            onKeyPress={!onKeyPress ? (e) => { e.key === 'Enter' && e.preventDefault(); }: onKeyPress}
            label={title}
            openTo="date"
            inputValue={value || null}
            name={name}
            disableToolbar={false}
            onChange={(date, value) => {
                onChange({ target: { value: date && date._isValid && moment(date).format('DD/MM/YYYY'), name } })
                console.log()
            }}
            value={value || null}
            format={'DD/MM/YYYY'}
            placeholder="10/10/2010"
            style={{ margin: 0 }}
            minDate={minDate}
            minDateMessage='estas seleccionando una fecha invalida'
        />
    )
}

export default DateField