import React from 'react'




//componentes
import LayoutTest from '../../../Layout/LayoutTest';
import Table from '../../Table/CustomTable';
import ModalDialog from '../../Modals/ModalDialog';
import FormTrayectoria from './FormTrayectoria';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';
import useModal from '../../../hooks/useModal'; 

//Api
import { TrayectoriasApi } from '../../../api/TrayectoriasApi';

//helper
import { decodeToken } from '../../../helpers/authToken';


//componente para mostrar la trayectoria de un expediente
export default function Trayectorias({ expedienteId,isFinalizado=false }) {

  const modalNuevaTrayectoria = useModal();
  


  //carga de datos utilizando el hook para cargar tabla
  const { dataTable, loading,recargarTabla } = useCargarTabla(new TrayectoriasApi().getTrayectoriaDeExpediente,expedienteId)


//columnas de la tabla trayectoria
  const columns = [
    { title: 'Fecha', name: 'created_at' },
    { title: 'Participantes', name: 'nombre' },
  ]

   //botones de trayectoria
   const buttons = [

    {
      icon: 'nuevo',
      action: () => modalNuevaTrayectoria.openModal(),
      label: 'Nuevo Participante'

    },
  ]


  return (
    <LayoutTest

      buttons={(decodeToken('admin') && !isFinalizado) ? buttons:[]}
    >

      { <ModalDialog
      closeModal={() => modalNuevaTrayectoria.closeModal()}
      active={modalNuevaTrayectoria.modal}
      hiddenButton={true}
      >
        <FormTrayectoria expedienteID={expedienteId} recargarTabla={recargarTabla} fnCloseModal={modalNuevaTrayectoria.closeModal}></FormTrayectoria>
      </ModalDialog>
      }

      <Table
        tableData={dataTable}
        //RowMenu={()=><ActionMenu onClick={()=>{}} options={options}/>}
        collapseTable={false}
        columns={columns}
        actions={[]}
        loading={loading}
      />
    </LayoutTest>
  )
}
