import AxiosIntance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Actividades
export class ActividadesApi {

    addActividadDeExpediente(body){
        return AxiosIntance.post(`actividades`,body)
    }

    getActividadesDeExpediente(expedienteId){
        
        return AxiosIntance.get(`actividades/${expedienteId}`);
    }

}