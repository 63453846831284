import React,{useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';


export default function LinkContainer({linkGoogleDocs}) {
    const [loading,setLoading] = useState(true)

    useEffect(()=>{
      setTimeout(()=>setLoading(false),250);
    },[])

    
    return (
        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
            {
                loading ? <CircularProgress />:
                <a style={{color:'#22b2e1', fontSize:'15px'}} href={linkGoogleDocs} target='_blank' rel='noreferrer'>{linkGoogleDocs}</a>
            }
        </div>
    )
}
