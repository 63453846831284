import AxiosInstance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Archivos
export class ArchivosApi {

    addArchivosDeExpediente(body){
        return AxiosInstance.post(`archivos`,body,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
    }

    getArchivosDeExpediente(expedienteId){
        
        return AxiosInstance.get(`archivos/${expedienteId}`);
    }
    
    downloadFile(body){
        console.log("downloadFIle body: ", body)
        return AxiosInstance.post(`archivos/download`,body)
    }

    updateFile(body){
        return AxiosInstance.put(`archivos/update`,body,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
    }

    deleteFile(archivoId){
      console.log('archivoId: ',archivoId);
      return AxiosInstance.delete(`archivos/delete/${archivoId}`)
    }

}