import { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { TableCell } from "@material-ui/core";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import Icons from "../../components/Icons";
import { IconButton } from "@material-ui/core";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//actions
//import { openModal } from '../../Redux/actions/modales/ActionCreatorModals';
//import { selectDataAction } from '../../Redux/actions/globalActions/ActionCreatorGlobal'

//custom hooks
import { usePagination } from "../../hooks/usePagination";


//component
//import Arrow from '../../hooks/useArrow';
import RowHeader from "../../components/Table/RowHeader";
import { Row } from "../../components/Row";
import TablePaginationActions from "../../components/Table/TablePaginationActions";
import { Cell, CellAction,CellCheckBox } from "../../components/Cell";


// estilos css-in-js
const useStyles = makeStyles(() =>
    createStyles({
        table: {
            minWidth: 500,
            width: "100% !important",
            overflowX: "scroll",
            overflowY: "scroll",
            textOverflow: 'elipsis'
        },
        paginationTable: {
            color: "#6E6893",
            height: 35,
        },
        footer: {
            height: 35,
        },
        root: {

            "& > *": {
                textOverflow: 'elipsis',
                borderBottom: "1px solid rgb(241 241 241)",
            },
        },
        cellHead: {
            '&:hover': {
                cursor: 'pointer',
            }
        },
        loading: {
            width: "100%",
        },

        collapseTable: {
            overflow: "hidden",
            background: "#FFF",
            textOverflow: 'elipsis'
        },

        borderOpen: {
            textOverflow: 'elipsis',
            borderLeft: "solid 5px #8cbaff ",
            background: "#d9e8ff",
        },

        borderClose: {
            borderLeft: "solid 5px transparent ",
        },
        noResult: {
            marginLeft:'40%'
          }
    })
);


const CustomTableCheckBox = (props) => {
    const classes = useStyles();
   
    //const arrow = useArrow();

    const {

        tableData,
        columns,
        actions = null,
        RowMenu = null,
        styleRowOptions = { display: "flex", justifyContent: "flex-end" },
        pagination = true,
    loading = false,
    filterLoading = false,
        openModal = () => { },
        selected=[],
        setSelected=()=>{},
    } = props;

    const theme = useTheme();
    const matchesLG = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.up('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesXL = useMediaQuery(theme.breakpoints.up('xl'));
  
  
  
  
  
  
    // usePagination devuelve:
    // page: devuelve la pagina actual,
    // rowperPage: devuelve las filas por pagina,
    // handleChangePage: fun. para cambiar pagina,
    const rowsPerPage = matchesXL ? 12 : matchesLG ? 10 : matchesMD ? 8 : matchesSM ? 6 : 5

    const { page, handleChangePage } = usePagination();


    useEffect((event)=>{
        handleChangePage(event,0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[filterLoading,loading])

    // emptyRows se usa para rellenar el espacio faltante
    // de la tabla para mantener el tamaño correspondiente
    // a filas por pagina

    const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tableData.length === 0 ? 0 : (tableData.length -  page * rowsPerPage));

    const EnhancedTableToolbar = (props) => {
        const { numSelected, unSelectAll } = props;

        return (
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(numSelected > 0 && {
                        bgcolor: '#eef6b0',
                    }),
                }}
            >
                {numSelected > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} seleccionado(s)
                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >

                    </Typography>
                )}

                {numSelected > 0 ? (
                    <>
                        <Tooltip title="Borrar seleccion">
                            <IconButton onClick={unSelectAll}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Editar seleccionados">
                            <IconButton onClick={() => {
                                openModal()
                            }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : null}
            </Toolbar>
        );
    };

    const isSelected = (dataId) => { 

        let selectedFilter = selected.filter(item => item.id ===dataId)
        if(selectedFilter.length === 1) return true
        return false
    };


    const indexOfCustom = (dataId) =>{

        for(let i =0; i<selected.length;i++){

            if(selected[i].id === dataId){
                return i;
            }
        }

        return -1
    }



    const handleClick = (event, data) => {
        const selectedIndex = indexOfCustom(data.id);
        console.log('selectedIndex: ',selectedIndex)
        let newSelected = [];



        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, data);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    

    const unSelectAll = (event) => {
        setSelected([])
    }

    // funcion que corta el array dependiendo las filas por paginas
    // y selecciona la lista correspondiente

    const ActualPage = (listData) => {
        return listData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };
/* 
    let actualDataPage = () => {

        let dataPage = tableData.length ? (rowsPerPage > 0 ? ActualPage(tableData) : tableData) : [];

        console.log('   sadsadasdas', dataPage)
        return dataPage;
    } */
    return (
        <>
            { selected.length>0 ? <EnhancedTableToolbar unSelectAll={unSelectAll} numSelected={selected.length} />:null}
            <Table aria-label='tabla'>

                <RowHeader columns={columns}>
                    <Cell variant='head' className={classes.cellHead}>
                        Seleccion
                    </Cell>
                    {columns.map((col, i) => (
                        <Cell variant="head" className={classes.cellHead} onClick={() => col.action()} key={i} >
                            {col.title}
                        </Cell>
                    ))}
                </RowHeader>
                <TableBody>

                {loading || filterLoading ? (
            <>

              <TableCell style={{width: '100%'}} colSpan={15}>
                <LinearProgress color='warning'/>
              </TableCell>

              <TableRow style={{ height: 35 * (rowsPerPage ? rowsPerPage : emptyRows) }}>
                <TableCell colSpan={15} />
              </TableRow>
            </>

          ) : null
          }

                    {
                        tableData.length && !loading && !filterLoading ?
                        (rowsPerPage > 0 ? ActualPage(tableData) : tableData).map(
                            (data, i) => {
                                const isItemSelected = isSelected(data.id);
                                //const labelId = `enhanced-table-checkbox-${data.id}`;
                                //console.log(`${data.numero}: `,isItemSelected)

                                return (
                                    <>
                                        <Row
                                            hover
                                            role="checkbox"
                                            //aria-checked={isItemSelected}

                                            tabIndex={1}
                                            key={data.id}

                                            selected={isItemSelected}
                                            
                                        >
                                            <CellCheckBox 
                                             key={i}
                                             onClick={(event) => handleClick(event, {id: data.id, caratula:data.caratula, numero:data.numero})}
                                             check={isItemSelected}
                                             checkAll={false}
                                            />
                                            {columns.map((key, j) => {
                                                return key.title === " " ? null : (
                                                    <Cell
                                                        key={j}
                                                        allRowData={data}
                                                        columna={key.title}
                                                        openModal={
                                                            () =>
                                                                key.openModal &&
                                                                null /* dispatch(openModal()) */
                                                        }
                                                        boolean={key.boolean}
                                                        isArray={key.isArray}
                                                        width={key.width}
                                                        action={key.action}
                                                    >
                                                        {key.type === "boolean" ? (
                                                            <Icons
                                                                type={
                                                                    key.name
                                                                        ? !!data[key.name] ||
                                                                        !!data[key.name.toLowerCase()]
                                                                        : !!data[key.title.toLowerCase()]
                                                                }
                                                            />
                                                        ) : key.name ? (
                                                            data[key.name] || data[key.name.toLowerCase()]
                                                        ) : (
                                                            data[key.title.toLowerCase()]
                                                        )}
                                                    </Cell>
                                                );
                                            })}

                                            <CellAction align="left">
                                                <div style={styleRowOptions}>
                                                    {/* RowMenu recibe los datos de la fila para, stearlos en el estado de redux*/}
                                                    {RowMenu && RowMenu(data)}
                                                    {actions ? actions.map((accion, index) => {
                                                        //console.log('tiene propiedad archivo: ', data.hasOwnProperty('archivo'))
                                                        //console.log('accion: ',accion, ' index: ',index, ' data: ', data)
                                                       
                                                            return (
                                                                <ButtonIcon
                                                                    key={index}
                                                                    onClick={() => accion.action(data)}
                                                                    textAlign={accion.align}
                                                                    centerIcon={accion.icon}
                                                                />
                                                            );
                                                        
                                                    }) : null}
                                                </div>
                                            </CellAction>

                                        </Row>

                                    </>
                                )
                            }
                        )
                    :null}


                     {/*---------------------------------------------------------*/}
                     {
          (tableData.length === 0 && !loading && !filterLoading) ? (
            
            <TableRow style={{ height: 57 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
              {console.log('no hay nada aca')}
              <TableCell colSpan={15}><Grid className={classes.noResult}><Typography>No hay resultados</Typography></Grid> </TableCell>
            </TableRow>)
            :
          
          (emptyRows > 0 && !loading && !filterLoading) ? (
                        <TableRow style={{ height: 50 * (!tableData.length ? emptyRows - 1 : emptyRows) }}>
                            <TableCell colSpan={15} />
                        </TableRow>
                    ) : null
                    }

                    
                </TableBody>

                <TableFooter className={classes.footer}>
                    {pagination ? (
                        <TableRow>
                            <TablePagination
                                className={classes.paginationTable}
                                align="right"
                                count={tableData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[]}
                                onChangePage={handleChangePage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    ) : null}
                </TableFooter>

            </Table>
        </>
    );
};

export default CustomTableCheckBox;
