import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export default function RadioGroupCustom({ name, label, onChange,defaultValue='No' }) {
  return (
    <FormControl >
      <FormLabel >{label}</FormLabel>
      <MuiRadioGroup row
        aria-label={label}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
      >
        <FormControlLabel value="Si" control={<Radio sx={{
          color: green[800],
          '&.Mui-checked': {
            color: green[600],
          },
        }} />} label="Si" />
        <FormControlLabel value="No" control={<Radio color='secondary' />} label="No" />
        
      </MuiRadioGroup>
    </FormControl>
  );
}