import AxiosInstance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Actividades
export class InformesApi {

    addInformeDeExpediente(body){
        return AxiosInstance.post(`informes`,body)
    }

    addArchivoAInforme(body){
        return AxiosInstance.post(`archivosinformes/subirarchivo`,body,{
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
    }

    downloadFile(body){
        console.log("downloadFIle body: ", body)
        const informe_id = body.informe_id;
        return AxiosInstance.post(`archivosinformes/descargar/${informe_id}`,body)
    }

    getInformesDeExpediente(expedienteId){
        
        return AxiosInstance.get(`informes/${expedienteId}`);
    }

    getCategoriasInforme(){
        return AxiosInstance.get(`informes/categorias`)
    }



}