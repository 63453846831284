import React,{useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
//import Badge from '@mui/material/Badge';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import BarChartIcon from '@mui/icons-material/BarChart';
//import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';


import {removeStoredAuthToken} from '../../helpers/authToken';
import {decodeToken} from '../../helpers/authToken';

import NotificationContainer from './NotificationContainer';

import { UserApi } from '../../api/UserApi';

import { tokenIsExpired } from '../../helpers/authToken';
import { useSnackbar } from 'notistack';


import history from '../../helpers/history';

/* const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 1,
    top: 4,
    border: `2px solid #b0d5f8`,
   
  },
})); */


const notifications = ['Notification 1', 'Notification 2', 'Notification 3', 'Notification 4', 'Notification 5', 'Notification 6', 'Notification 7',
'Notification 8','Notification 9', 'Notification 10']

export default function AccountMenu() {
  //const [bellIcon, setBellIcon]

  const { enqueueSnackbar } = useSnackbar(); 

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [isAdmin,setIsAdmin] = useState(false);
  //const [notificationCount, setNotificationCount] =React.useState(notifications.length);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorElNotifications);
  const setAdminValueInLocalState = async() =>{
    await new UserApi().isSuperAdmin(decodeToken('id'))
    .then(resp=>{
      setIsAdmin(parseInt(resp.data[0].isAdmin));
      localStorage.setItem('admin',resp.data[0].isAdmin);
      
    })
    .catch(err=>{
      enqueueSnackbar('Fallo de conexion con la base de datos', { variant: 'error' })
    })
  }
  const handleClick = async (event) => {
    
    if (tokenIsExpired()) {

      history.push('/');
      return;
     }
    setAdminValueInLocalState();
    setAnchorEl(event.currentTarget);

    

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

 /* const handleNotifications = (event)=>{
    setAnchorElNotifications(event.currentTarget);
    setNotificationCount(0);
    
  } */ 

  const handleCloseNotifications = () =>{
    setAnchorElNotifications(null);
  }


  const goToPage = (path) =>{
    history.push(path)
  }
 

  return (
    <React.Fragment>
      <Container sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'flex-end'}}>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Typography sx={{ minWidth: 100, fontWeight: 'lighter', }}>{decodeToken('name')}</Typography>
        
        {/* <Tooltip title='Salir'>
        <IconButton size='small'>
          <Logout />
        </IconButton>
        </Tooltip> */}
        {/* <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
          onClick={handleNotifications}
        >
        <StyledBadge  badgeContent={notificationCount} color="error" overlap='circular'><NotificationsIcon fontSize='inherit'></NotificationsIcon></StyledBadge>
        </IconButton> */}
        <Tooltip title="Ajustes de cuenta">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }}/>
          </IconButton>
        </Tooltip>

      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
          {false ?[<MenuItem onClick={()=> goToPage('/estadisticas')}>
        <ListItemIcon>
          <BarChartIcon fontSize="small" sx={{color:'#EBC522'}}/>
        </ListItemIcon>
        <div style={{color: '#3e3e3'}}>
          Estadisticas
          </div>
        </MenuItem>,
        <MenuItem onClick={()=> goToPage('/user')}>
          <ListItemIcon>
            <PersonIcon fontSize="small" sx={{color:'#EBC522'}}/>
          </ListItemIcon>
          <div style={{color: '#3e3e3'}}>
          Usuarios
          </div>
        </MenuItem>].map(item => item)
        

        :null}
       
        <MenuItem onClick={()=>removeStoredAuthToken()}>
          <ListItemIcon>
            <Logout fontSize="small" sx={{color:'#EBC522'}} />
          </ListItemIcon>
          <div style={{color: '#3e3e3'}}>
          Cerrar Sesion
          </div>
        </MenuItem>
        
      </Menu>

      <Menu
        anchorEl={anchorElNotifications}
        open={openNotification}
        onClose={handleCloseNotifications}
        onClick={handleCloseNotifications}
        PaperProps={{
          elevation: 4,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
       
        <NotificationContainer notifications={notifications} />
      </Menu>
      </Container>
    </React.Fragment>
  );
}