import React from 'react'
import {Divider} from '@material-ui/core'
import CheckBox from './CheckBox';





export default function ListaDeConvenios({convenios, setSelected, fnCloseModal=()=>{}}) {

    const indexOfCustom = (dataId) =>{

        for(let i =0; i<convenios.length;i++){
    
            if(convenios[i].id === dataId){
                return i;
            }
        }
    
        return -1
    }
    
    
    const handleClick = (event, data) => {
        event.preventDefault();
        const selectedIndex = indexOfCustom(data.id);
        console.log('selectedIndex: ',selectedIndex)
        let newSelected = [];
    
    
    
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(convenios, data);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(convenios.slice(1));
        } else if (selectedIndex === convenios.length - 1) {
            newSelected = newSelected.concat(convenios.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                convenios.slice(0, selectedIndex),
                convenios.slice(selectedIndex + 1),
            );
        }
    
        setSelected(newSelected);
    
        if(newSelected.length === 0) fnCloseModal()
    };
    return (
        <div >
            {  convenios.map((selected,index) =>
                                <>
                                <Divider />
                                <div style={{display: 'flex'}}>
                                    <CheckBox check={true} onClick={(event)=>handleClick(event,selected)}/><div key={selected.id} style={{ marginTop: '10px',marginBottom: '10px' }}
                                > {index+1}: {selected.caratula}</div>
                                </div>
                                </>
                                )}
        </div>
    )
}
