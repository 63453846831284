import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Tabs, Tab, Grid } from "@material-ui/core";
import { makeStyles, createStyles} from '@material-ui/core/styles';
import { UserApi } from '../api/UserApi';
import { decodeToken } from '../helpers/authToken';


const useStyles = makeStyles((theme) =>
  createStyles({
    rootTab: {
      textTransform: 'none',
      minWidth: 'max-content',
      minHeight: 35,
      padding: '0px 10px',
      fontSize: '0.9rem',
      '@media (min-width:600px)': {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },

    tabSelect: {
      color: '#EBC522',
      fontWeight: 600,
    },
    tab: {
      color: '#EBC522',
      fontWeight: 400,
      border: 'solid 1px transparent',
    },
    tabs: {
      minHeight: 45,
      background: '#FFF',
    },
    indicator: {
      background: '#EBC522'
    },
    pagination:{
      margin:'0px 20px',
      color: '#8cbaff',
      fontWeight: 400,
    }
  }))
export default function AppBar() {

  const setAdminValueInLocalState = async() =>{
    await new UserApi().isSuperAdmin(decodeToken('id'))
    .then(resp=>{
      localStorage.setItem('admin',resp.data[0].isAdmin);
      
    })
    .catch(err=>{
      console.log('error ');
    })
  }

  const tabs = [
    { label: "Convenios en Gestion", value: "/expedientes" || "/expedientes/"  },
    { label: "Convenios Finalizados", value: "/expedientesfinalizados" },
    decodeToken('id')=== '1' ? {label: "Convenios Dictamen", value:"/dictamen"}: ''
  ]

  const tabsAdmin = [
    { label: "Convenios en Gestion", value: "/expedientes" },
    { label: "Convenios Finalizados", value: "/expedientesfinalizados" },
    {label: "Asignar tipos a convenios", value: "/asignartipos"},
    {label: "Convenios Dictamen", value:"/dictamen"}
  ]

  const [currentTag, setCurrenTag] = useState('')

  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      <Grid container item xs={12} justifyContent="space-between" alignItems="center">
        <Tabs
          value={currentTag || window.location.pathname}
          className={classes.tabs}
          onChange={(e, currTag) => {
            setAdminValueInLocalState();
            setCurrenTag(currTag)}
          }
          classes={{
            indicator: classes.indicator
          }}>
          {((decodeToken('admin') === 1) ? tabsAdmin : tabs).map((tab,index) => {
            return <Tab
              key={index}
              disableFocusRipple={true}
              disableRipple={true}
              className={`${classes.rootTab} ${window.location.pathname === tab.value ? classes.tabSelect : classes.tab}`}
              value={`${tab.value}`}
              label={tab.label}
              component={Link}
              to={`${tab.value}`}
            />
          })}
        </Tabs>
      </Grid>
    </Grid>

  )
}
