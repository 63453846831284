import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import pattern from 'patternomaly';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);



/* const scores = [6, 5, 5, 5, 3, 4, 6, 4, 5];
const labels = ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']; */

const options = {
  
  scales: {
    y: {
      min: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

 

export default function BarChart({datos}) {

   
    const [labels, scores1, scores2] = datos;
    console.log('datos: ',datos);

    console.log('labels',labels);
    console.log('scores1: ',scores1);
    console.log('scores2: ',scores2);

    //let tiposDeConvenio = scores1[0]

    

   const data = {
      datasets: [
        {
          label: "Tipos de Convenios",
          tension: 0.3,
          data: scores1,scores2,
          backgroundColor: [
            pattern.draw('weave','#ff6384'),
            pattern.draw('line', '#36a2eb'),
            pattern.draw('diagonal-right-left','#17becf'),
            'rgba(75, 192, 192, 0.2)',
            'rgba(153, 102, 255, 0.2)',
            'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(23, 190, 207, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 1
        },
      ],
      labels,
    };
    

    
  

  return  <Bar data={data} options={options} />;
}