import React from 'react'
import {Route} from "react-router-dom";
import {tokenIsExpired} from '../../helpers/authToken';
import history from '../../helpers/history';


//Componente para crear una ruta personalizada para autenticar
export default function AuthRoute(props) {
    return (
        <>
        {
            tokenIsExpired()?history.push('/'): <Route {...props}/>
        }
        </>
    )
}
