
import { useContext } from 'react'
import { Grid } from '@material-ui/core';
import FormContainer from '../../Forms/FormContainer';
import { useSnackbar } from "notistack";
// Import components
import Controls from '../../Forms/Controls'

// Custom hooks
import { useForm } from '../../../hooks/useForm'
import {decodeToken} from '../../../helpers/authToken';



//Api
import { ArchivosApi } from '../../../api/ArchivosApi';

//contexto
import { AppStateContext } from '../../../Contexts/AppStateContextProvider';






//forumulario de nuevo archivo
const FormArchivo = ({ width, recargarTabla, data=null,fnCloseModal = () => { },edit=false }) => {

 console.log('id: ', decodeToken('id'))

  //valores iniciales del formulario
const initialFValues = {
  descripcion: data?data.descripcion:'',
  archivo: '',
  created_by: decodeToken('id'),
  updated_by: decodeToken('id'),
}

const { enqueueSnackbar } = useSnackbar();


  //funciones para utilizar el formulario traidas desde el hook useForm
  const {
    values,
    handleChangeForm,
  } = useForm(initialFValues);


  const onSubmit = (e, values) => {

    e.preventDefault();

    console.log('value. archivo: ', values.archivo)


    const archivoForm = new FormData(e.currentTarget)
    //archivoForm.append('descripcion', values.descripcion);
    //archivoForm.append('archivo', values.archivo);
    archivoForm.append('idexpediente', values.idexpediente)
    archivoForm.append('created_by', values.created_by)
    archivoForm.append('updated_by', values.updated_by)

    console.log('values: ', archivoForm.getAll('archivo'))


    new ArchivosApi().addArchivosDeExpediente(archivoForm)
    .then((response) => {
      enqueueSnackbar("Archivo cargado con exito", { variant: "success" });
      recargarTabla();
      fnCloseModal();
    })
    .catch((err) => {
      enqueueSnackbar("Error al cargar el archivo", { variant: "error" });
    });
 

  }


  const onSubmitEdit = (e, values) => {

    e.preventDefault();

    console.log('value. archivo: ', values.archivo)


    const archivoForm = new FormData(e.currentTarget)
    //archivoForm.append('descripcion', values.descripcion);
    //archivoForm.append('archivo', values.archivo);
    archivoForm.append('idexpediente', values.idexpediente)
    archivoForm.append('created_by', values.created_by)
    archivoForm.append('filename',data.archivo);
    archivoForm.append('archivoid',data.id);
    archivoForm.append('updated_by', values.updated_by)

    console.log('values: ', archivoForm.getAll('archivo'))


    new ArchivosApi().updateFile(archivoForm)
    .then((response) => {
      enqueueSnackbar("Archivo editado con exito", { variant: "success" });
      recargarTabla();
      fnCloseModal();
    })
    .catch((err) => {
      enqueueSnackbar("Error al editar el archivo", { variant: "error" });
    });

  }



  //obtencion del id de expediente
  const appStateContext = useContext(AppStateContext);
  values.idexpediente = appStateContext.appState;

  console.log('dataEditar: ',data)
  

  //console.log('value form archivo:  ',values)


  //funcion submit del formulario 
  //const { formSubmit } = useSubmit( new ArchivosApi().addArchivosDeExpediente, archivo, recargarTabla,resetForm)



  return (

    <FormContainer
      width={width}
      height={'28vh'}
      LabelButtonSubmit="Guardar"
      //resetForm={resetForm}
      buttonResetForm={false}
      title={"Agregar un archivo"}
      onSubmit={edit?(e) => onSubmitEdit(e, values):(e) => onSubmit(e, values)}
    //style={{ width:'20vw',  overflowX: 'hidden' }}
    >
      <Grid style={{ marginTop: '20px' }} item xs={12}>
        <Controls.Input
          fullwidth
          name="descripcion"
          label="Descripcion"
          rows={3}
          multiline
          value={values.descripcion}
          onChange={handleChangeForm}
        />
      </Grid>

      <Grid style={{ marginTop: '20px' }} item xs={12}>
        <Controls.Input
          fullwidth
          name="archivo"
          value={values.archivo}
          type="file"
          onChange={handleChangeForm}
        />
      </Grid>




    </FormContainer>

  )
}
export default FormArchivo