import AxiosInstance from "../hooks/axiosInstace";

//clase para llamar a los metodos del modulo Anotaciones
export class AnotacionesApi {

    addAnotacionDeExpediente(body){
        return AxiosInstance.post(`anotaciones`,body)
    }

    getAnotacion(anotacionId){
        return AxiosInstance.get(`anotaciones/anotacion/${anotacionId}`)
    }

    getAnotacionesDeExpediente(expedienteId){
        
        
        return AxiosInstance.get(`anotaciones/${expedienteId}`);
    }

    deleteAnotacionDeExpediente(anotacionId){
        return AxiosInstance.delete(`anotaciones/${anotacionId}`)
    }

    updateAnotacionDeExpediente(body){
        let anotacionId=body.anotacionId;
        delete  body.anotacionId
        return AxiosInstance.put(`anotaciones/${anotacionId}`,body)
    }

}