import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';

const useSearchFilter = (asyncSearchFunction,recargarTabla=()=>{},) => {

    const { enqueueSnackbar } = useSnackbar(); 

    const [searchText, setSearchText] = useState(['','',''])
    const [filterTable, setFilterTable] = useState([])
    const [buscar, setBuscar] = useState(false)
    const [showFilter, setShowFilter] =useState(false)
    const [filterLoading, setFilterLoading] = useState(false);
    //const [filter, setFilter] = useState('')


    const searchFunction = () => {
        //console.log("SEARCH TEXT ", searchText)
        //console.log(buscar)

        

        if(buscar){
            setFilterLoading(true)
            console.log('searchText: ',searchText)



            if(searchText[0] ===''  && searchText[1] ===''  && searchText[2] ==='' ){
                console.log('cancelando show filter ');
                setShowFilter(false)
                setFilterTable([]);
                recargarTabla();
                setTimeout(
                    ()=>setFilterLoading(false)
                    ,700
                )
            }else{
                asyncSearchFunction(searchText)
                .then(resp => {
                    setShowFilter(true)
                    setFilterTable(resp.data)
                    setTimeout(
                        ()=>setFilterLoading(false)
                        ,700
                    )

                })
                .catch(err => 
                    {
                        setTimeout(
                            ()=>setFilterLoading(false)
                            ,700
                        )
                        setShowFilter(true);
                        setFilterTable([]);
                        enqueueSnackbar('No se pudo obtener datos', { variant: 'error' })
                    })
            }

            

        }
            
       
    }

    const changeBuscar=()=>setBuscar(!buscar)

    useEffect(() => {
        searchFunction()

        setBuscar(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText,buscar])

    return {
        filterTable,
        setSearchText,
        showFilter,
        changeBuscar,
        filterLoading,
        setShowFilter,
    }

}

export default useSearchFilter;