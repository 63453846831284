import React, { useState, useEffect } from 'react'
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

//components

import ModalDialog from '../../components/Modals/ModalDialog';
import ListaDeConvenios from './ListaDeConvenios';

//api 
import { ExpedientesApi } from '../../api/ExpedientesApi'
import FormContainer from '../../components/Forms/FormContainer';

//hooks
import useModal from '../../hooks/useModal';



export default function FormAsignarConvenios({ width = '100%',
    fnCloseModal = () => { }, recargarTabla, idexpediente, caratulaConvenio = '', selected = [], setSelected = () => { },editar=false,tipoconvenioid='' }) {

    const useStyles = makeStyles((theme) =>
        createStyles({
            root: {
                width: width,
                background: "#FFF",
                height: '100%',

                '& .MuiFormControl-root': {
                    width: '100%',
                    height: '100%'
                },
            },
            fieldsContainer: {
                height: "80%",
                padding: '10px !important'
            },
            formTitle: {
                fontWeight: 300,
            },
            formTitleContainer: {
                overflowY: 'scroll',
                margin: 10
            },
            buttons: {
                height: 'auto',
                alignItems: 'center'
            }

        }))

    const classes = useStyles();
    
    console.log("tipoconvenioid: ", tipoconvenioid);

    const [selectedTipo, setSelectedTipo] = useState(tipoconvenioid * 1);
    const [tipoDeConvenios, setTipoDeConvenios] = useState([]);
    const [conveniosMarco, setConveniosMarco] = useState([]);
    const [selectedConvenioMarco, setSelectedConvenioMarco] = useState('');
    const [componenteConvenioMarco, setComponenteConvenioMarco] = useState(false);


    useEffect(() => {
        new ExpedientesApi().getTiposDeConvenios()
            .then(resp => setTipoDeConvenios(resp.data))
            .catch(err => console.error(err))


            const selectAll = '?selectAll=true'
        new ExpedientesApi().getConveniosMarco(selectAll)
        .then(resp => setConveniosMarco(resp.data))
        .catch(err =>console.error(err))
    }, [])

   
    const modalConfirmacion = useModal();

    const handleOnChange = (event, value) => {
        event.preventDefault();
        console.log('value : ', value);
        
        if (value.id === 2 ||
            value.id === 3 ||
            value.id === 8) {

            setComponenteConvenioMarco(true);

          } else {

            setComponenteConvenioMarco(false);

          }
          setSelectedTipo(value !== null ? value.id : "");



        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const idObject = {
            idexpediente: selected.length ? selected : idexpediente,
            idTipoConvenio: selectedTipo,
            conveniomarcoid:selectedConvenioMarco,
        }



        await new ExpedientesApi().updateTipoExpediente(idObject)
        setSelected([]);
        recargarTabla();
        fnCloseModal();


    }

    console.log('tipo de convenios: ', tipoDeConvenios)
    console.log('tipo seleccionado: ', selectedTipo )
    console.log('conveniso marcos: ', conveniosMarco)

    return (
        <>
            {<ModalDialog
                
                title='Confirmacion para asignar tipos'
                labelButton="Confirmar"
                closeModal={() => modalConfirmacion.closeModal()}
                accionAceptar={(event) => {
                    modalConfirmacion.closeModal()
                    handleSubmit(event)
                }}
                active={modalConfirmacion.modal}
            >
                <div>{`¿Desea asignar este tipo a ${selected.length ? 'esos':'este'} convenio?`}</div>
            </ModalDialog>}


            <FormContainer

                LabelButtonSubmit="Guardar"
                //resetForm={resetForm}
                buttonResetForm={false}
                title={"Asignar Tipo a convenio"}

                onSubmit={(event) => {
                    event.preventDefault();
                    modalConfirmacion.openModal()
                }}
            >





                <Grid item xs={12}>


                    <Autocomplete
                        disablePortal
                        fullWidth
                        id="tipoconvenio"
                        isOptionEqualToValue={(option, value) => option.id === value? value.id:null}
                        options={tipoDeConvenios}
                        value={selectedTipo}
                        onChange={(event, value) => handleOnChange(event, value === null? '':value)
                        }
                        getOptionLabel={(option) => option.nombre}
                        renderInput={(params) => (
                            <TextField {...params} required label="Tipo de Convenios" />
                        )}
                    />

                </Grid>
                {componenteConvenioMarco && <Grid item xs={12}>
                    <Autocomplete
                     disablePortal
                     fullWidth
                     id="conveniomarco"
                     isOptionEqualToValue={(option, value) => option.id === value.id}
                     options={conveniosMarco}
                     onChange={(event, value) => {event.preventDefault();
                        setSelectedConvenioMarco(value === null ? '' :value.id);
                    }
                     }
                     getOptionLabel={(option) => `${option.numero} | ${option.caratula}`}
                     renderInput={(params) => (
                         <TextField {...params} required label="Convenios Marcos" />
                     )}
                    />

                    
                </Grid>}
                <Grid item style={{ overflowY: 'auto', height: '50vh' }} xs={12}>
                    <Typography className={classes.formTitle} variant='h6'
                    >{caratulaConvenio ? caratulaConvenio :
                        <ListaDeConvenios convenios={selected} setSelected={setSelected} fnCloseModal={fnCloseModal} />}</Typography>
                </Grid>






            </FormContainer>

        </>
    )
}
