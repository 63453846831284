export const generarNumeroDeConvenio=(contadorArea)=>{



    let numeroDigitos = contadorArea.length;

    let contador =
      numeroDigitos > 1 && numeroDigitos < 4
        ? numeroDigitos < 3
          ? "0" + contadorArea
          : contadorArea
        : "00" + contadorArea;

        return contador

}


export const generarNombreCaratula = (nombreTipoConvenio,partesDeConvenio=[],contrapartes=[]) => {
    if(nombreTipoConvenio === '') return ''

    let caratula = nombreTipoConvenio + " UCU";

    //console.log("partes de convenio: ", partesDeConvenio);

    /* if (partesDeConvenio.length) {
      caratula += " , ";
      partesDeConvenio.map((parte, index) => {

        console.log('parte ',parte)
        if (partesDeConvenio.length === index + 1) {
          return caratula += parte.nombre + " ";
        } else {
          return caratula += parte.nombre + ", ";
        }
      });
    } */

    console.log("contraparte nombre caratula", contrapartes);
    if (contrapartes.length) {
      contrapartes.map((contraparte) => {
        return caratula = caratula + " - " + contraparte + " ";
      });
    }

    return caratula;
  };

//convierte un string con formato DD/MM/YYYY a MM/DD/YYYY
  export const formatFecha = (fecha) =>{

    let arrayFecha = fecha.split('/');

    let month = arrayFecha[1];

    arrayFecha[1] = arrayFecha[0];

    arrayFecha[0] = month;

    return arrayFecha.join('/');


  }

  //compara 2 fechas que sean string con formato DD/MM/YYYY
  export const compareDates = (firstDate, secondDate) =>{

    return new Date(formatFecha(firstDate)) > new Date(formatFecha(secondDate))

  }
