import React,{ useState} from 'react'




//componentes
import LayoutTest from '../../../Layout/LayoutTest';
import Table from '../../Table/CustomTable';
import Modal from '../../Modals/Modal';
import ModalDialog from '../../Modals/ModalDialog';
import FormAnotaciones from './FormAnotaciones';

//hooks
import useCargarTabla from '../../../hooks/useCargarTabla';
import useModal from '../../../hooks/useModal';


//Api
import {AnotacionesApi} from '../../../api/AnotacionesApi';


//Modal de Anotaciones de expediente recibe el id de expediente y devuelve el componente renderizado
export default function Anotaciones({expedienteId}) {
    
  //carga de anotaciones utilizando el hook useCargarTabal()
    const {dataTable,loading,recargarTabla}= useCargarTabla( new AnotacionesApi().getAnotacionesDeExpediente,expedienteId)

    //estado de modal para mostrar el form de nueva anotacion
    const modalNuevaAnotacion = useModal()
    const modalBorrarAnotacion = useModal();
    const modalEditarAnotacion= useModal();
    const [anotacionId,setAnotacionId] = useState('');
    const [descripcionDeAnotacion, setDescripcionDeAnotacion] = useState('');




    const funcionAceptarBorrarAnotacion=()=>{

      console.log("funcion aceptar")
          new AnotacionesApi().deleteAnotacionDeExpediente(anotacionId);
          recargarTabla();
          modalBorrarAnotacion.closeModal();
    }

    //columnas de la tabla Anotaciones
    const columns = [
      
        { title: 'Descripcion', name:'descripcion',enableEnviar:"false"},

      ]

      //botones de ANotaciones
    const buttons = [
        {
          icon: 'noteadd',
          action: () => modalNuevaAnotacion.openModal(),
          label: 'Nueva anotacion'
    
        },
        
      ]

      //const idAnotacion ='';

      const actions = [
        {
          icon: "editar",
          action: (data) => { 
            setDescripcionDeAnotacion(data.descripcion);
            setAnotacionId(data.id) 
            modalEditarAnotacion.openModal()},
        },
        {
            icon: 'borrar',
            action: (data)=> 
                { setAnotacionId(data.id) 
                      modalBorrarAnotacion.openModal()},
        }
      ]

    return (
        <LayoutTest
    
      buttons={buttons}
    >

{
        <ModalDialog
        labelButton="Anotaciones label"
        closeModal={() => modalEditarAnotacion.closeModal()}
        active={modalEditarAnotacion.modal}
        fondo='none'
        hiddenButton={true}
      >
        
        <FormAnotaciones recargarTabla={recargarTabla} edit={true} anotacionId={anotacionId} 
        descripcion={descripcionDeAnotacion} fnCloseModal={()=>modalEditarAnotacion.closeModal()} />
        
      </ModalDialog>
      }


     {
        <ModalDialog
        title='Borrar Anotacion'
        closeModal={() => modalBorrarAnotacion.closeModal()}
        active={modalBorrarAnotacion.modal}
        accionAceptar={funcionAceptarBorrarAnotacion}
        fondo='none'
        children="¿Esta seguro de querer eliminar esta Anotacion?"

      >
        
        
      </ModalDialog>
      }
      {
        <ModalDialog
        labelButton="Anotaciones label"
        closeModal={() => modalNuevaAnotacion.closeModal()}
        active={modalNuevaAnotacion.modal}
        fondo='none'
        hiddenButton={true}

      >
        <FormAnotaciones recargarTabla={recargarTabla} edit={false} fnCloseModal={()=>modalNuevaAnotacion.closeModal()}></FormAnotaciones>
        
      </ModalDialog>
      }

      {
      <Table
        tableData={dataTable}
        //RowMenu={()=><ActionMenu onClick={()=>{}} options={options}/>}
        collapseTable={false}
        columns={columns}
        actions={actions}
        loading={loading}
      />
      }
      
    </LayoutTest>
    )
}
