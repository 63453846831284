import { IconButton, Tooltip, Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from '../../helpers/history';

import logoUCU from './ucu50.png'

import Header from '../../components/Header/Header';

const useStyles = makeStyles((theme) =>
    createStyles({

        layoutContainer: {
            background: '#fff9e0',
            height: 'max-content',
            minHeight: '100vh',




        },
        headerContainer: {


            height: '10vh',
            width: '100vw',
            background: '#b0d5f8',
            //background: '#f4f241',
        },
        paper: {


            width: '35vw',



        },
        chart: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        mainContainer: {
            height: '100%',
            display:'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

        },
        goBackButton: {
            position: 'relative',
            top: theme.spacing(1),
            left: theme.spacing(4),

        },
        img:{
            width: '100%',
            height: '50vh',
        },
        text:{
            fontFamily: 'Source Sans Pro',
            fontWeight: 'lighter'
        },
        link:{
            color:'#2196f3',
            margin:0,
            padding:0,
            '&:hover': {
                color: '#6bbef9',
                cursor: 'pointer',
            },
            
        }


    }),
);


const goBackPage = ()=>{
    
    history.goBack()

}

export default function NotFound() {

    const classes = useStyles();

    return (
        <Grid className={classes.layoutContainer}>

            <Header menu={false}/>

            <Grid >
                    <Tooltip title="Volver a la pagina anterior" arrow>
                        <IconButton className={classes.goBackButton} onClick={goBackPage}>
                            <ArrowBackIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                </Grid>
            <Grid className={classes.mainContainer}>

                

                <Grid className={classes.chart}>
                    <div className={classes.paper}>

                    <img className={classes.img} src={logoUCU} alt='logoUcu' />
                        

                    </div>
                    
                </Grid>
                <Typography className={classes.text} variant='h4'>La página solicitada no pudo encontrarse.</Typography>
                <Typography className={classes.text} variant='h4'>Vuelva a la <strong className={classes.link} onClick={goBackPage}>pagina</strong> anterior</Typography>

            </Grid>

            

        </Grid>
    )
}
