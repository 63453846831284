import { useState } from 'react';

//hook con funciones para el formulario
export const useForm = (initialFValues, nuevo=false) => {

  const [values, setValues] = useState(initialFValues);

 /*  const { detallesData } = []

  const cargarDatosEdit = () => {
    setValues({
      ...values,
      ...detallesData
    })
  } */

  const handleChangeForm = ({ target }) => {
    const { name, value } = target
    console.log("valores de formularios: ",value)
   /*  let fecha = null
    if(typeof value === "object") {
      fecha = value.toISOString().slice(0,10)
    } */
    setValues({
      ...values,
      [name]: /* fecha ? fecha: */ value 
    })
  }

  /* useEffect(() => {
    if (Object.entries(detallesData).length && !nuevo) cargarDatosEdit()
  }, [Object.values(initialFValues)[0]])
 */
  const resetForm = () => {
    setValues(initialFValues);
  }

  return {
    values,
    setValues,
    handleChangeForm,
    resetForm,
    formEdit: ""
  }
}
  