import { useState,useEffect} from "react";
import { Grid, Typography, IconButton } from "@material-ui/core";
import FormContainer from "../../../components/Forms/FormContainer";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@material-ui/icons/Delete";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioGroupCustom from './RadioGroupCustom';
import useMediaQuery from '@mui/material/useMediaQuery';


//refactorizar utils
//utils
import { generarNumeroDeConvenio, generarNombreCaratula, compareDates } from '../../../helpers/utils';
import {decodeToken} from '../../../helpers/authToken';


// Import components
import Controls from "../../../components/Forms/Controls";



//Api
import { ExpedientesApi } from "../../../api/ExpedientesApi";
import { UserApi } from "../../../api/UserApi";
import {AreaApi } from "../../../api/AreaApi";


//checkbox icons
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//formulario para cargar un nuevo convenio
const FormConvenios = ({
  recargarTabla,
  fnCloseModal = () => { },
}) => {

  const matchFullHD = useMediaQuery('(min-width:2000px)');
  const { enqueueSnackbar } = useSnackbar();

  //estados de datos del formulario
  const [partesDeConvenio, setPartesDeConvenio] = useState([]);
  const [caratula, setCaratula] = useState('');
  const [componenteConvenioMarco, setComponenteConvenioMarco] = useState(false);
  const [componenteFechaFin, setComponenteFechaFin] = useState(true);
  const [tipoDeConvenios, setTipoDeConvenios] = useState([]);
  const [conveniosMarco, setConveniosMarco] = useState([]);
  const [users, setUsers] = useState([]);
  const [minDateVencimiento, setMinDateVencimiento] = useState(new Date());
  const [maxDateConvenioMarco, setMaxDateConvenioMarco] = useState('');
  const [errorContrapartes, setErrorContrapartes] = useState(false);


  const schema = yup
    .object({
      objeto: yup.string().required("Este campo es requerido"),   
    })
    .required();

  const {
    control,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numero: "",
      objeto: "",
      vigenciade: "",
      a: null,
      tipoconvenio: "",
      conveniomarco: "",
      contraparte: "",
      user_id: decodeToken('id'),
      created_by: decodeToken('id'),
      updated_by: decodeToken('id'),
    },
    resolver: yupResolver(schema),
  });



  const [contrapartes, setContrapartes] = useState([]);



  useEffect( () => {

    async function fetchData(){

      const results = await Promise.all([ 
        new UserApi().getAllUsers(decodeToken('id')),
        new ExpedientesApi().getTiposDeConvenios(),
        new ExpedientesApi().getConveniosMarco()]);
  
        const [usersResult, tiposDeConveniosResult, conveniosMarcoResult] = results;
  
        setUsers(usersResult.data);
        setTipoDeConvenios(tiposDeConveniosResult.data);
        setConveniosMarco(conveniosMarcoResult.data);
    }

    fetchData();

   

  },[])

 

  //console.log('tipo de convenios estado', tipoDeConvenios);
  //console.log('users: ',users);

  //podria hacer algo con esto (pensar)
  const handleOnChangeAutocomplete = (event, onChange, value) => {

    onChange({ target: { value: value !== null ? value : "" } });

    if (value?.id === 2 ||
      value?.id === 3 ||
      value?.id === 8) {

      setComponenteConvenioMarco(true);

    } else {

      setComponenteConvenioMarco(false);
      setMaxDateConvenioMarco('');

    }
  
    setCaratula(generarNombreCaratula(value !== null ? value.nombre : ""));

  };

  //poner nombre mas descriptivo
  const handleOnClick = () => {
    if (getValues("contraparte") !== ""){
      setContrapartes(contrapartes.concat(getValues("contraparte")));
      
    }
      

    console.log(contrapartes)
    resetField("contraparte");

  };




  //reducir esto
  const onSubmit = async (data, e) => {

    if(contrapartes.length <= 0){
      setErrorContrapartes(true);
      return;
    }

    setErrorContrapartes(false);

    //comparar fecha entre el convenio marco  y el convenio especifico perteneciente a ese marco
    if(getValues('a') !== null && maxDateConvenioMarco != '' && maxDateConvenioMarco !== null) {
      if(compareDates(getValues('a'),maxDateConvenioMarco)){
        enqueueSnackbar("Convenio supera la fecha del convenio marco", { variant: "error" });
      return;
      }
      
    }
    console.log(caratula);

    let areaUsuario = await new AreaApi()
      .getArea(decodeToken('areaid'))
      .then((response) => {
        
        return response.data[0];
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("No se ha podido crear el convenio", { variant: "error" });
      });


      
      if(!areaUsuario) return;
    //console.log("area: ", areaUsuario);


    let contadorDeConvenios = generarNumeroDeConvenio(areaUsuario ? areaUsuario.contador.toString(): null);



    let caratulaDinamica = generarNombreCaratula(data.tipoconvenio.nombre, partesDeConvenio, contrapartes)
    console.log(
      "caratula dinamica despues de ejecutar funcion: ",
      caratulaDinamica
    );

    let anio = data.vigenciade.split('/')[2];

    //data.numero = areaUsuario.codigoarea + " " + contadorDeConvenios + "/" + anio;
    let factual = new Date();
    let anioActual = factual.getFullYear();
    data.numero = areaUsuario.codigoarea + " " + contadorDeConvenios + "/" + anioActual;
    data.tipoconvenio = data.tipoconvenio.id;
    data.areaid = decodeToken('areaid');
    data.contador = areaUsuario.contador;
    data.anio = anio;
    data.contrapartes = contrapartes;
    data.partesdeconvenio = partesDeConvenio;
    data.caratula = caratulaDinamica;
    data.conveniomarco = (
      data.tipoconvenio === 1
      || data.tipoconvenio === 3 ||
      data.tipoconvenio === 6) ? '' : data.conveniomarco.id;

    console.log(data);


    await new ExpedientesApi()
      .postExpediente(data)
      .then((response) => {

        enqueueSnackbar("convenio cargado con exito", { variant: "success" });
        recargarTabla();
        fnCloseModal();
      })
      .catch((err) => {
        console.log('error: ',err.response.data.message);
        enqueueSnackbar(err.response.data.message? err.response.data.message: "No se ha podido crear el convenio", { variant: "error" });
      });
  };

  
  return (
    <>
      <FormContainer
        width="40vw"
        LabelButtonSubmit="Guardar"
        buttonResetForm={false}
        //resetForm={resetForm}
        title={"Nuevo Convenio"}
        onSubmit={handleSubmit(onSubmit)}
      >

        <Grid container item xs={12}>
          <Controller
            name="tipoconvenio"
            control={control}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                fullWidth
                size={matchFullHD?'medium':'small'}
                id="tipoconvenio"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={tipoDeConvenios}
                onChange={(event, values) =>
                  handleOnChangeAutocomplete(event, onChange, values)
                }
                getOptionLabel={(option) => option.nombre}
                renderInput={(params) => (
                  <TextField {...params} required label="Tipo de Convenios" />
                )}
                
                
              />
            )}
          />
        </Grid>

        {componenteConvenioMarco &&
          <Grid container item xs={12}>
            <Controller
              name="conveniomarco"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  size={matchFullHD?'medium':'small'}
                  id="conveniomarco"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={conveniosMarco}
                  onChange={(event, values) =>{
                    onChange(values !== null ? values : "")
                    console.log('values: ',values?values: 'nada')
                    setMaxDateConvenioMarco(values?values.a: 'nada');
                  }

                  }
                  getOptionLabel={(option) => option.caratula}
                  renderInput={(params) => (
                    <TextField {...params} required label="Convenios Marco" />
                  )}
                />
              )}
            />
          </Grid>
        }

        

        <Grid item xs={12}>
          <Autocomplete
            fullWidth={true}
            multiple
            onChange={(event, values) => {
              setPartesDeConvenio(values)
              console.log('parte de convenio: ', values)
            }}
            id="id"
            size={matchFullHD?'medium':'small'}
            options={users}
            groupBy={(option) => option.areas}
            disableCloseOnSelect
            getOptionLabel={(option) => option.nombre}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox

                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}

                />
                {option.nombre}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size={matchFullHD?'medium':'small'}
                required={partesDeConvenio.length <=0 }
                label="Partes del convenio"
                placeholder="Partes"
              />
            )}
          />
        </Grid>
              <Grid item  container xs={12} >

       
              <Grid  item xs={8} >
            <Controller
              name="contraparte"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  
                  required={contrapartes.length<=0}
                  name="contraparte"
                  size={matchFullHD?'medium':'small'}
                  label="Contraparte"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            </Grid>
                

                

            <Grid item xs={4}>
                
            <Controls.Button
            text='Agregar'
            size='small'
            onClick={handleOnClick}
            variant='form'
            />
            </Grid>
             
            
            </Grid>

            {(contrapartes.length<=0 && errorContrapartes) && <Grid item xs={12}>{<p style={{ color: '#ff1414' }}>'Debes agregar al menos una contraparte con el boton "Agregar" '</p>}</Grid>}
          
        


        {contrapartes.length ? (
          <Grid item xs={12}>
            <Typography variant="h5">Contrapartes</Typography>

            {contrapartes.map((contraparte, index) => (
              <Paper
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                  minHeight: "30px",
                }}
              >
                <div style={{ marginLeft: "10px" }}>{contraparte}</div>{" "}
                <IconButton
                  onClick={() =>
                    setContrapartes(
                      contrapartes.filter(
                        (contraparte) => contraparte !== contrapartes[index]
                      )
                    )
                  }
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Paper>
            ))}
          </Grid>
        ) : null}


        <Grid container item xs={12}>
          <Controller
            name="objeto"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <TextField
                  rows={3}
                  multiline
                  size='medium'
                  aria-invalid={true}
                  name="objeto"
                  label="Objeto"
                  onChange={onChange}
                  value={value}
                  error={errors.objeto ? true : false}
                />
                
              </>
            )}
          />
        </Grid>
        
        {errors.objeto && <Grid item xs={12}>{<p style={{ color: '#ff1414' }}>{errors.objeto?.message}</p>}</Grid>}


        <Grid item xs={12}>
          <Controller
            name="renovacion"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroupCustom
                required
                name="renovacion"
                label="¿Tiene renovacion Automatica?"
                value={value}
                onChange={(event, value) => {
                  onChange(event, value);
                  console.log('renovacion: ', value);
                  if (value === 'Si') setComponenteFechaFin(false);
                  else setComponenteFechaFin(true);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="impactopresupuestario"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroupCustom
                name="impactopresupuestario"
                label="¿Tiene impacto presupuestario?"
                value={value}
                onChange={(event, value) => 
                  onChange(event, value)}
              />
            )}
          />
        </Grid>



         <Grid container alignItems="center" item xs={12}>
          <Grid item xs={12}>
            <Controller
              name="vigenciade"
              control={control}
              render={({ field: { onChange, value }}) => (
                <Controls.DateField
                  name="vigenciade"
                  required={true}
                  title="Fecha inicio"
                  value={value}
                  size={matchFullHD?'medium':'small'}
                  minDate={'1/01/1994'}
                  onChange={(event)=>{
                    console.log('event value: ',event.target.value)
                    if(event.target.value.length>0){
                    var dateParts = event.target.value.split("/");
                    setMinDateVencimiento(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]));
                    }
                    onChange(event)}}
                />
              )}
            />
          </Grid>
        </Grid>

    

        {componenteFechaFin && 
          <Grid container alignItems="center" item xs={12}>
           
              <Controller
                name="a"
                control={control}
                render={({ field: { onChange, value }}) => (
                  <Controls.DateField 
                  size='small'
                  name="a" 
                  required={true}
                  title="Fecha fin" 
                  value={value}
                  minDate={minDateVencimiento}
                  onChange={(event)=>{
                    /* console.log('minDateVencimiento ',minDateVencimiento);
                    console.log('event: ',event); */
                    onChange(event)}} />
                )}
              />
            
          </Grid>
        }


      </FormContainer>
    </>
  );
};
export default FormConvenios;
