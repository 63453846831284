import React, { useState, useEffect } from 'react'
import { Paper, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from '../../helpers/history';

//import LineChart from './LineChart';
//Components
import { Grid } from '@material-ui/core';

import BarChart from './BarChart';

import Header from '../../components/Header/Header';

import PieChart from './PieChart';

//import PolarAreaChart from './PolarAreaChart';


//api

import { ExpedientesApi } from '../../api/ExpedientesApi';




//#e0eefb
//#fafbe0
const useStyles = makeStyles((theme) =>
    createStyles({

        layoutContainer: {
            background: '#fff9e0',
            height: 'max-content',
            minHeight: '100vh',


        },
        headerContainer: {


            height: '10vh',
            width: '100vw',
            background: '#b0d5f8',
            //background: '#f4f241',
        },
        paper: {


            width: '35vw',



        },
        chart: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5vh',
        },
        mainContainer: {
            height: '90vh',


        },
        goBackButton: {
            position: 'relative',
            top: theme.spacing(3),
            left: theme.spacing(4),

        }


    }),
);

const goBackPage = () => {

    history.goBack()

}


export default function Estadisticas() {

    const [conveniosPorTipo, setConveniosPorTipo] = useState([]);
    const [tiposDeConvenioPorAnio, setTiposDeConvenioPorAnio] = useState([]);



    useEffect(() => {



        async function fetchData() {

            await new ExpedientesApi().getConveniosPorTipo()
                .then(resp => {
                    //console.log('tipos de convenios: ', resp.data);
                    setConveniosPorTipo(resp.data)
                })

            await new ExpedientesApi().getTipoDeConvenioPorAnio()
                .then(resp => {
                    console.log('tiposde convenios por anio: ', resp.data);
                    setTiposDeConvenioPorAnio(resp.data);
                })

        }

        fetchData();



    }, [])


    const classes = useStyles();

    return (
        <Grid className={classes.layoutContainer}>



            <Header />

            <Grid className={classes.mainContainer}>

                <Grid >
                    <Tooltip title="Volver a la pagina anterior" arrow>
                        <IconButton className={classes.goBackButton} onClick={goBackPage}>
                            <ArrowBackIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid className={classes.chart}>
                    <Paper className={classes.paper}>

                        <PieChart datos={conveniosPorTipo} />

                    </Paper>
                    {/* <Paper className={classes.chart}>
                    <BarChart datos={tiposDeConvenioPorAnio} />
                </Paper> */}
                    {/* <Paper className={classes.paper}>
                    <PieChart datos={tiposDeConvenioPorAnio} />
                </Paper> */}
                </Grid>

                <Grid className={classes.chart}>
                    <Paper className={classes.paper}>
                        <Typography>Tipos de convenio por año</Typography>
                        <table>
                            <tr>
                                <th>Año</th>
                                <th>Tipo de convenio</th>
                                <th>numero de convenios</th>
                            </tr>
                            <tr>
                                <td>John</td>
                                <td>Doe</td>
                            </tr>
                            <tr>
                                <td>Jane</td>
                                <td>Doe</td>
                            </tr>
                            <tr>
                                <td>Jane</td>
                                <td>Doe</td>
                            </tr>
                        </table>
                    </Paper>
                </Grid>
            </Grid>

        </Grid>

    )
}